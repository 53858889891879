import { PatientProtocolEnum } from '../../../../uc-api-sdk';

export const careProtocols = {
  [PatientProtocolEnum.HTN]: {
    title: 'HTN Care Protocol',
    content: [
      'Tech onboarding and initial visit will be completed by the CM/CA.',
      'BP baseline should be collected from EHR transcription or in-clinic first measurement.',
      'Medication reconciliation and reminder setup during the initial visit and every follow-up.',
      'Ensure an in-clinic follow-up visit with CM/CA after provider\'s visit, 10 minutes per visit.',
      'Additional visit will be offered for uncontrolled patients (BP > 140/90), and assign to an RD/HC.',
    ],
  },
  [PatientProtocolEnum.DM]: {
    title: 'DM Care Protocol',
    content: [
      'Tech onboarding and initial visit will be completed by the CM/CA.',
      'BG baseline should be collected from EHR or by having the doctor place the A1C order.',
      'Medication reconciliation and reminder setup during the initial visit and every follow-up.',
      'Ensure an in-clinic follow-up visit with CM/CA after provider\'s visit, 10 minutes per visit.',
      'Additional visit will be done by RD/HC every 3 months.',
    ],
  },
  [PatientProtocolEnum.PRE_DM]: {
    title: 'DM Care Protocol',
    content: [
      'Tech onboarding and initial visit will be completed by the CM/CA.',
      'BG baseline should be collected from EHR or by having the doctor place the A1C order.',
      'Medication reconciliation and reminder setup during the initial visit and every follow-up.',
      'Ensure an in-clinic follow-up visit with CM/CA after provider\'s visit, 10 minutes per visit.',
      'Additional visit will be done by RD/HC every 3 months.',
    ],
  },
  [PatientProtocolEnum.OTHER]: {
    title: 'Default Care Protocol',
    content: [
      'Tech onboarding and initial visit will be completed by the CM/CA.',
      'Medication reconciliation and reminder setup during the initial visit and every follow-up.',
      'Ensure an in-clinic follow-up visit with CM/CA after provider\'s visit, 10 minutes per visit.',
    ],
  },
};

export const defaultCareProtocol = {
  title: 'Default Care Protocol',
  content: [
    'Tech onboarding and initial visit will be completed by the CM/CA.',
    'Medication reconciliation and reminder setup during the initial visit and every follow-up.',
    'Ensure an in-clinic follow-up visit with CM/CA after provider\'s visit, 10 minutes per visit.',
  ],
};
