import {
  Button, Col, Form, Row
} from 'antd';
import moment, { Moment } from 'moment';
import { useMemo, useState } from 'react';
import { map } from 'lodash';
import { useMedicationForm } from '../../../../hooks/useMedicationForm/useMedicationForm';
import { MedicationTypeSelectComponent as FormItemMedicationTypeInput } from '../../../../selectComponents/MedicationTypeSelectComponent/MedicationTypeSelectComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { MedicationDeleteConfirmationModal } from '../../../../uiComponent/Medication/MedicationDeleteConfirmationModal';
import { MedicationDosageInputComponent } from '../../../../uiComponent/Medication/MedicationDosageInput';
import { MedicationEndDateInputComponent } from '../../../../uiComponent/Medication/MedicationEndDateInputComponent';
import { MedicationHintComponent } from '../../../../uiComponent/Medication/MedicationHintComponent';
import { MedicationNoteInputComponent } from '../../../../uiComponent/Medication/MedicationNoteInputComponent/MedicationNoteInputComponent';
import { MedicationStartDateInputComponent } from '../../../../uiComponent/Medication/MedicationStartDateInputComponent';
import { MedicationStatusSelectComponent } from '../../../../uiComponent/Medication/MedicationStatusSelectComponent';
import { PrescribedCheckboxComponent } from '../../../../uiComponent/Medication/PrescribedCheckboxComponent';
import { MeidcationNameContainer } from '../../container/MedicationNameContainer';
import { MedicationType } from '../../type/type';
import './AddEditMedicationFormComponent.scss';
import { MedicationDosageFrequencySelectComponent } from '../MedicationDosageFrequencySelectComponent/MedicationDosageFrequencySelectComponent';
import { MedicationReminderCardFormItemComponent } from '../MedicationReminderCardFormItemComponent/MedicationReminderCardFormItemComponent';
import { MedicationReminderFormValue } from '../../hook/useMedicationFormReminder';
import { MedicationInstructionSelectComponent } from '../MedicationInstructionSelectComponent/MedicationInstructionSelectComponent';

export interface AddEditMedicationFormComponentProps {
  initialValues?: MedicationType,
  onSubmit?: (v: MedicationType) => void,
  submitText?: string;
  autoEnableReminder?: boolean;
}

export const AddEditMedicationFormComponent = ({
  initialValues,
  onSubmit,
  submitText = 'Add',
  autoEnableReminder,
}: AddEditMedicationFormComponentProps) => {
  const form = Form.useForm()[0];
  const hook = useMedicationForm({ form });
  const [startDate, setStartDate] = useState<Moment |
    string | null>(initialValues?.startDate ? moment(initialValues?.startDate) : null);
  const [endDate, setEndDate] = useState<Moment | string | null>(
    initialValues?.endDate ? moment(initialValues?.endDate) : null,
  );

  const pInitialValues = useMemo(() => ({
    ...initialValues,
    enableReminder: !!autoEnableReminder || !!initialValues?.reminders,
    reminders: map(initialValues?.reminders, ({ hour, minute }) => ({
      timeSelect: (typeof hour === 'number' && typeof minute === 'number')
        ? (hour * 60 + minute)
        : undefined,
    })),
  }) as MedicationType, [initialValues, autoEnableReminder]);

  const onStartDateChange = (value: Moment | null) => {
    form.setFieldsValue({ startDate: value });
    setStartDate(value);
  };

  const onEndDateChange = (value: Moment | null) => {
    form.setFieldsValue({ discontinueDate: value });
    setEndDate(value);
  };

  const handleSubmit = (v: MedicationType) => {
    const {
      enableReminder,
      reminders,
      ...restV
    } = (v || {}) as (MedicationType & MedicationReminderFormValue);
    const parsedReminders = map(reminders, ({ timeSelect }) => ({
      hour: Math.floor(timeSelect / 60),
      minute: timeSelect % 60
    }));
    onSubmit?.({
      ...restV,
      reminders: enableReminder ? parsedReminders : undefined,
    });
    form.resetFields();
  };

  return (
    <Form
      id="medicationObjectForm"
      form={form}
      onFinish={hook.handleSubmitAndReset(handleSubmit)}
      initialValues={pInitialValues}
      layout="vertical"
    >
      <Row gutter={15}>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('name')}
            className="required-field"
            required
          >
            <MeidcationNameContainer />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('type')}
            className="required-field"
            required
          >
            <FormItemMedicationTypeInput />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('status')}
          >
            <MedicationStatusSelectComponent />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('instruction')}
          >
            <MedicationInstructionSelectComponent />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('startDate')}
          >
            <MedicationStartDateInputComponent
              onChange={onStartDateChange}
              disabledAfterDate={endDate}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('endDate')}
          >
            <MedicationEndDateInputComponent
              disabledBeforeDate={startDate}
              onChange={onEndDateChange}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('frequency')}
          >
            <MedicationDosageFrequencySelectComponent
              onChange={hook.handleDosageFrequencyChange}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('dosage')}
            required
            className="required-field"
          >
            <MedicationDosageInputComponent />
          </FormItem>
        </Col>
      </Row>
      <div className="my12">
        <MedicationReminderCardFormItemComponent />
      </div>
      <FormItem
        info={hook.getInfo('note')}
        className="medicationNote"
      >
        <MedicationNoteInputComponent />
      </FormItem>
      <FormItem
        name={hook.getName('otherMD')}
      >
        <PrescribedCheckboxComponent />
      </FormItem>
      <FormItem>
        <MedicationHintComponent date={endDate} />
      </FormItem>

      <FormItem className="">
        <Button key="submit" htmlType="submit" type="primary">{submitText}</Button>
        {initialValues?.id && <MedicationDeleteConfirmationModal medId={initialValues?.id} />}
      </FormItem>
    </Form>
  );
};
