import { Skeleton, Space } from 'antd';
import { PatientInfo, usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ProgramCategoryEnumListComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { VitalEnumTypeListComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { LabelValueComponent } from '../../../../uiComponent/LabelValueComponent/LabelValueComponent';
import { OutstandingItemsTagComponent } from '../../../outstanding/component/OutstandingItemsTagComponent/OutstandingItemsTagComponent';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';
import { LastFollowupVisitContainer } from '../../../visit/container/LastFollowupVisitContainer/LastFollowupVisitContainer';
import './WorklistPatientInfoComponent.scss';

export interface WorklistPatientInfoComponentProps {
  patientId: string;
}

export const WorklistPatientInfoComponent = ({
  patientId,
}: WorklistPatientInfoComponentProps) => {
  const {
    info: {
      outstandingService
    } = {},
  } = usePatientContext();

  const totalOutstandingCount = outstandingService?.getTotalCount() || 0;
  const visitCount = outstandingService?.outstandingItemCount?.visitCount || 0;

  const shouldShowOutstandingTag = (
    // has outstanding items
    totalOutstandingCount > 0
    // has outstanding item other than visit
    && totalOutstandingCount > visitCount
  );

  const renderInfo = (info?: PatientInfo) => (
    <Space direction="vertical" className="w100">
      {
        shouldShowOutstandingTag
        && (
          <div className="flex jc-sb ai-c">
            <OutstandingItemsTagComponent />
          </div>
        )
      }
      <LabelValueComponent
        label="Clinic:"
        value={(
          <ClinicNameDisplayComponent
            clinic={info?.patientInfo?.clinic || undefined}
          />
        )}
      />
      <LabelValueComponent
        label="Status:"
        value={(
          <>
            {info?.enrolledProgramService.isEnrolled() ? 'Enrolled' : 'Unenrolled'}
            {' - '}
            <ProgramCategoryEnumListComponent
              value={info?.enrolledProgramService.getPrograms()}
              type="short"
            />
          </>
        )}
      />
      <Space wrap>
        <LabelValueComponent
          label="Vitals:"
          value={(
            <VitalEnumTypeListComponent
              value={info?.enrolledProgramService.getVitals()}
              type="short"
              splitter=", "
            />
          )}
        />
      </Space>
      <LabelValueComponent
        label="Last Follow-up Visit:"
        value={(<LastFollowupVisitContainer patientId={info?.patientInfo?.id} />)}
      />
    </Space>
  );

  return (
    <div className="worklist-patient-info-component">
      <PatientProfileContainer
        patientId={patientId}
        getEnrolledProgram
        getControlLevel
        getBpBaseline
      >
        {(patientInfo, isLoading) => (
          isLoading
            ? <Skeleton loading active />
            : renderInfo(patientInfo)
        )}
      </PatientProfileContainer>
    </div>
  );
};
