import { ReactNode } from 'react';
import {
  usePatientGetHealthCondition,
  usePatientGetPatientComplexityById
} from '../../../../uc-api-sdk';
import { InterventionContainerProps } from '../../../intervention/type';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { ICDTableComponent } from '../../../ICDCode/component/ICDTableComponent/ICDTableComponent';
import { DefaultICDCodeFormValues } from '../../../ICDCode/component/ICDCodeFormComponent/type';
import { ComplexityComponent } from '../../../complexity/component/ComplexityComponent/ComplexityComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { PatientHealthConditionComplexityFormContainer } from '../../../patient/container/PatientHealthConditionComplexityFormContainer/PatientHealthConditionComplexityFormContainer';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface ProblemsICDCodeValues extends DefaultICDCodeFormValues { }

export interface ProblemsICDCodeContainerProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps {
  title?: ReactNode;
  isInCharting?: boolean;
}

export const ProblemsICDCodeContainer = ({
  title = 'ICD Code',
  patientId,
  visitId,
  onEdit,
  isEditing,
  onSubmit,
  onError,
  onValuesChange,
  formButtons,
  onCancel,
  showHeaderOutside,
  showEdit,
  isInCharting = false,
}: ProblemsICDCodeContainerProps) => {
  if (!patientId) return null;

  const healthConditionInfo = usePatientGetHealthCondition({
    params: { memberId: patientId },
  });

  const patientComplexityInfo = usePatientGetPatientComplexityById({
    params: { memberId: patientId },
  });

  const refetchPatientHeader = useUpdate('PATIENT_INFO_UPDATED');
  useUpdateListener('PATIENT_INFO_UPDATED', () => {
    healthConditionInfo.refetch();
    patientComplexityInfo.refetch();
  });

  const healthConditions = healthConditionInfo.data?.data?.healthConditions || [];
  const patientComplexity = patientComplexityInfo.data?.data || {};

  const isLoading = healthConditionInfo.data?.data === undefined
    || patientComplexityInfo.data?.data === undefined;

  const handleOnSubmit = () => {
    healthConditionInfo.refetch();
    patientComplexityInfo.refetch();
    refetchPatientHeader.updateValue();
    onSubmit?.();
  };

  return (
    <PatientCommonCardComponent
      isInCharting={isInCharting}
      title={title}
      showHeaderOutside={showHeaderOutside}
      showEdit={showEdit}
      isEditing={isEditing}
      onEdit={onEdit}
      updateInfo={healthConditionInfo.data?.data || {}}
      content={(
        <div className="flex fd-c">
          <ICDTableComponent
            dataSource={healthConditions}
            disabled
          />
          <ComplexityComponent
            complexityLevel={patientComplexity.level || undefined}
            updatedByUser={patientComplexity.updatedByUser || {}}
          />
        </div>
      )}
      formContent={(
        <LoadingOverlayComponent
          isLoading={isLoading}
          showSkeleton
        >
          <PatientHealthConditionComplexityFormContainer
            patientId={patientId}
            visitId={visitId}
            initialValues={{
              icdTable: healthConditions,
              patientComplexity,
            }}
            onSubmit={handleOnSubmit}
            onError={onError}
            onValuesChange={onValuesChange}
            onCancel={onCancel}
            formButtons={formButtons}
          />
        </LoadingOverlayComponent>
      )}
    />
  );
};
