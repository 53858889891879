import { NamePath } from 'antd/lib/form/interface';
import { useCallback } from 'react';
import {
  FormInput,
  FormOptions, useFormHookFactory
} from '../useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../types/form';

// DEPRECATED
export const useIterableFormHook = <T extends FormInput, K extends keyof T>(
  mainFormItemName: string,
  formInput: T,
  options: FormOptions = {},
) => {
  const factory = useFormHookFactory(formInput, options);

  const getMainFormItemName = useCallback(() => mainFormItemName, [mainFormItemName]);

  const makeObjectNamePath = useCallback((
    inputKey: K,
    fieldName: string | number,
  ): NamePath => ([
    getMainFormItemName(),
    fieldName,
    factory.getName(inputKey),
  ]), [getMainFormItemName, factory.getName]);

  const makeListNamePath = useCallback((
    inputKey: K,
    fieldName: number,
  ): NamePath => ([
    fieldName,
    factory.getName(inputKey),
  ]), [factory.getName]);

  const getListValue = useCallback((
    inputKey: K,
    getFieldValue: GetFieldValue,
    fieldName?: number,
  ) => (
    typeof fieldName !== 'undefined'
      ? getFieldValue(makeObjectNamePath(inputKey, fieldName))
      : getFieldValue(factory.getName(inputKey))
  ), [makeObjectNamePath, factory.getName]);

  const getObjectValue = useCallback((
    inputKey: K,
    getFieldValue: GetFieldValue,
    fieldName?: string | number,
  ) => (
    typeof fieldName !== 'undefined'
      ? getFieldValue(makeObjectNamePath(inputKey, fieldName))
      : getFieldValue(factory.getName(inputKey))
  ), [makeObjectNamePath, factory.getName]);

  const shouldUpdateFromList = useCallback((
    inputKeys: K[],
    fieldName: string | number = -1,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => (prev: any, curr: any) => (
    inputKeys.some((inputKey) => (
      prev[getMainFormItemName()]?.[fieldName]?.[inputKey]
      !== curr[getMainFormItemName()]?.[fieldName]?.[inputKey]
    ))
  ), [
    getMainFormItemName,
  ]);

  return {
    ...factory,
    getMainFormItemName,
    makeObjectNamePath,
    makeListNamePath,
    getListValue,
    getObjectValue,
    shouldUpdateFromList,
  };
};
