import { WorklistStepEnum } from '../../features/worklist/component/WorklistDrawerComponent/WorklistDrawerComponent';
import { HtnLiteLabelComponent } from '../../uiComponent/HtnLiteLabelComponent/HtnLiteLabelComponent';
import { BasicEnumComponent } from '../BasicEnumComponent/BasicEnumComponent';

export interface WorklistStepEnumComponentProps {
  value: WorklistStepEnum;
  isPanelTitle?: boolean;
}

export const WorklistStepEnumComponent = ({
  value,
  isPanelTitle = false,
}: WorklistStepEnumComponentProps) => {
  const getTitleTag = (text: string) => (
    <div className="flex ai-c gap0">
      {text}
      {isPanelTitle && <HtnLiteLabelComponent />}
    </div>
  );

  const getText = () => {
    switch (value) {
      case WorklistStepEnum.GOALS_EDUCATION_SETUP:
        return 'Goals & Education Setup';
      case WorklistStepEnum.FOLLOW_UP_SCHEDULE:
        return getTitleTag('Schedule Follow-up Visit');
      case WorklistStepEnum.DISEASE_HISTORY_REVIEW:
        return getTitleTag('Disease History Review');
      default:
        return <BasicEnumComponent value={value} />;
    }
  };

  return (
    <span>{getText()}</span>
  );
};
