// @ts-nocheck
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Col, message, Row } from 'antd';
import moment from 'moment';
import {
  FC, useCallback, useMemo, useState
} from 'react';
import { MEDICATION_DATE_INSERT_FORMAT } from '../../../constants/timeFormat';
import {
  Medication,
  MedicationManagement,
  Nullable,
  useMedicationDelete,
  useMedicationInsert,
  useMedicationUpdate,
  usePatientUpdate
} from '../../../uc-api-sdk';
import { MedicationManagementType, MedicationTabFormComponent } from '../component/MedicationTabFormComponent/MedicationTabFormComponent';

import { usePatientContext } from '../../../contexts/PatientInfoContext/PatientInfoContext';
import useBoolean from '../../../hooks/useBoolean/useBoolean';
import { MedicationTableContainer } from './MedicationTableContainer';
import { useBillableTimeInterventionComp } from '../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../billableTime/contants/contants';

export interface MedicationContainerProps {
  form?: FormOptions['form'];
  memberId: string;
  showSubmitButtons?: boolean;
  showAdditionalNote?: boolean;
  disabled?: boolean;
}

export const MedicationContainer:
  FC<MedicationContainerProps> = ({
    form,
    memberId,
    showSubmitButtons = true,
    showAdditionalNote = true,
    disabled,
  }) => {
    const { info } = usePatientContext();
    const medicationManagement = info?.patientInfo?.profile?.medicationManagement;
    const { value: showButtons, setTrue, setFalse } = useBoolean(false);
    const [cur, setCur] = useState(0);
    const deleteMedication = useMedicationDelete({});
    const insertMedication = useMedicationInsert({});
    const updateMedication = useMedicationUpdate({});
    const patientUpdateInfo = usePatientUpdate({});
    const { send } = useBillableTimeInterventionComp();

    const handleClickAddMedication = () => {
      send({
        patientId: memberId,
        component: BillableTimeInterventionComponentEnum.UpdateMedication,
        detail: BillableTimeInterventionDetailEnum.MedicalHistoryMedicationListAddMedication,
      });
    };

    const onDelete = useCallback(async (medId: string) => {
      const result = await deleteMedication.send({
        params: {
          id: medId,
        },
      });
      if (result?.code === 200) {
        message.success('Medication deleted.');
        setCur((v) => v + 1);
      }
    }, []);

    const onInsert = useCallback(async (value: Medication) => {
      const result = await insertMedication.send({
        params: {
          document: {
            ...value,
            startDate: value.startDate
              ? moment(value.startDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
            endDate: value.endDate
              ? moment(value.endDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
            memberId,
            active: true,
          },
        },
      });

      if (result?.code === 200) {
        message.success('Medication added.');
        setCur((v) => v + 1);
      }
    }, [memberId]);

    const onUpdate = useCallback(
      async (value: Medication, id: Nullable<string> | undefined) => {
        const result = await updateMedication.send({
          params: {
            id: id || '',
            document: {

              ...value,
              startDate: value.startDate
                ? moment(value.startDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
              endDate: value.endDate
                ? moment(value.endDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
              memberId,
              id,
              active: true,
            },
          },
        });

        if (result?.code === 200) {
          message.success('Medication updated.');
          setCur((v) => v + 1);
        }
      },
      [updateMedication.send, setCur, memberId],
    );

    const onSave = useCallback(async (v: MedicationManagementType) => {
      const value: MedicationManagement = {
        otherInfo: v.otherInfo,
        extraNote: v.extraNote,
        reconciliationCompliance: v.reconciliationCompliance,
        reconciliationComplianceOther: v.reconciliationComplianceOther || null,
      };

      const result = await patientUpdateInfo.send({
        params: {
          id: memberId,
          document: {
            profile: {
              medicationManagement: value,
            },
          },
        },
      });
      if (result?.code === 200) {
        message.success('Profile updated.');
        setFalse();
      }
    }, [memberId]);

    const convertedInitValues = useMemo(() => {
      if (medicationManagement) {
        return {
          ...medicationManagement,
          reconciliationCompliance: medicationManagement?.reconciliationCompliance,
          reconciliationComplianceOther: medicationManagement?.reconciliationComplianceOther,
        };
      }
      return {};
    }, [medicationManagement]);

    return (
      <MedicationTabFormComponent
        form={form}
        onDelete={onDelete}
        onInsert={onInsert}
        onUpdate={onUpdate}
        onFinish={onSave}
        initialValues={convertedInitValues}
        showButtons={showSubmitButtons && showButtons}
        setTrue={setTrue}
        setFalse={setFalse}
        showAdditionalNote={showAdditionalNote}
        disabled={disabled}
        handleClickAddMedication={handleClickAddMedication}
        patientId={memberId}
      >
        <Row className="mb20">
          <Col span={24}>
            <MedicationTableContainer
              memberId={memberId}
              status={cur}
            />
          </Col>
        </Row>
        <Row className="mb20">
          <Col span={24}>
            <MedicationTableContainer
              memberId={memberId}
              status={cur}
              type="PREV"
            />
          </Col>
        </Row>
      </MedicationTabFormComponent>
    );
  };

export default MedicationContainer;
