import { DatePicker, Input } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthSubmissionDateFormItemComponent } from '../PriorAuthSubmissionDateFormItemComponent/PriorAuthSubmissionDateFormItemComponent';
import { CPTCodeFormItemComponent } from '../CPTCodeFormItemComponent/CPTCodeFormItemComponent';

export interface PriorAuthDeniedFormItemComponentProps {
  fieldName?: number;
}

export const PriorAuthDeniedFormItemComponent = ({
  fieldName,
}: PriorAuthDeniedFormItemComponentProps) => {
  const insuranceForm = useInsuranceForm();
  return (
    <>
      <PriorAuthSubmissionDateFormItemComponent
        fieldName={fieldName}
      />

      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthDeniedDate', fieldName)}
        label={insuranceForm.getLabel('priorAuthDeniedDate')}
      >
        <DatePicker
          format="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
        />
      </FormItem>

      <CPTCodeFormItemComponent fieldName={fieldName} />

      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthDeniedReason', fieldName)}
        label={insuranceForm.getLabel('priorAuthDeniedReason')}
      >
        <Input.TextArea
          autoSize={{ minRows: 3, maxRows: 3 }}
        />
      </FormItem>
    </>
  );
};
