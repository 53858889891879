import { Button, Divider } from 'antd';
import moment from 'moment';
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ConditionEnum, PatientControlLevel } from '../../../../uc-api-sdk';
import { ControlLevelEnumComponent } from '../../../../enumComponent/ControlLevelEnumComponent/ControlLevelEnumComponent';
import { ConditionIconComponent } from '../../../../uiComponent/ConditionIconComponent/ConditionIconComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import './ControlLevelCardComponent.scss';
import { A1CCustomizeGoalModalComponent } from '../../../A1C/component/A1CCustomizeGoalModalComponent/A1CCustomizeGoalModalComponent';
import { PatientHealthConditionControlLevelModalComponent } from '../../../patientCare/component/PatientHealthConditionControlLevelComponent/PatientHealthConditionControlLevelModalComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';

export interface ControlLevelCardComponentProps {
  patientId: string;
  cardInfo?: PatientControlLevel;
}

export const ControlLevelCardComponent = ({
  patientId,
  cardInfo,
}: ControlLevelCardComponentProps) => {
  const {
    isOpen: isEditModalOpen,
    open: setOpenEditModal,
    close: setCloseEditModal,
  } = useOpen();
  const {
    isOpen: isTooltipOpen,
    open: setOpenTooltip,
    close: setCloseTooltip,
  } = useOpen();

  const controlLevel = cardInfo?.controlLevel || undefined;

  const patientInfo = () => (
    <div className="mt12 ml20">
      This patient is
      {' '}
      <ControlLevelEnumComponent value={controlLevel?.controlLevel || undefined} type="name" />
    </div>
  );

  const bpMedian = () => {
    if (cardInfo?.bpStatData) {
      return (
        <div className="flex">
          {Math.floor(cardInfo?.bpStatData?.sbp ?? 0)}
          /
          {Math.floor(cardInfo?.bpStatData?.dbp ?? 0)}
        </div>
      );
    }
    return (
      <div className="flex fs1 gap0">
        <span>No data</span>
        <TooltipComponent
          className="secondary-gray"
          title="No measurement in the last month."
          placement="bottom"
          type="info-icon"
          showArrow
          color="secondary-gray"
          highlightOnHover={false}
          overlayStyle={{ minWidth: '250px' }}
        />
      </div>
    );
  };

  const bpBaseline = () => {
    if (cardInfo?.bpBaseline) {
      return (
        <div className="flex">
          {Math.floor(cardInfo?.bpBaseline?.sbp ?? 0)}
          /
          {Math.floor(cardInfo?.bpBaseline?.dbp ?? 0)}
        </div>
      );
    }
    return (
      <div className="flex fs1 gap0">
        <span>No data</span>
        <TooltipComponent
          className="secondary-gray"
          title="No initial transcription data or measurement data available within 7 days of enrollment."
          placement="bottom"
          type="info-icon"
          showArrow
          color="secondary-gray"
          highlightOnHover={false}
          overlayStyle={{ minWidth: '300px' }}
        />
      </div>
    );
  };

  const bpGoal = () => {
    const bpGoalValue = cardInfo?.bpGoal;
    const sbp = bpGoalValue && bpGoalValue.sbp ? Math.floor(bpGoalValue.sbp) : 140;
    const dbp = bpGoalValue && bpGoalValue.dbp ? Math.floor(bpGoalValue.dbp) : 90;
    return (
      <div className="flex">
        {sbp}
        /
        {dbp}
      </div>
    );
  };

  const lastestA1C = () => {
    if (cardInfo?.bgStatData) {
      return (
        <div className="flex">
          {cardInfo?.bgStatData?.latestA1c}
          %
        </div>
      );
    }
    return (
      <div className="flex fs1 gap0">
        <span>No data</span>
        <TooltipComponent
          className="secondary-gray"
          title="No A1c was acquired for this patient."
          placement="bottom"
          type="info-icon"
          showArrow
          color="secondary-gray"
          highlightOnHover={false}
          overlayStyle={{ minWidth: '260px' }}
        />
      </div>
    );
  };

  const bgBaseline = () => {
    if (cardInfo?.bgBaseline) {
      return (
        <div className="ControlLevelCardComponent">
          {cardInfo?.bgBaseline?.a1c}
          %
        </div>
      );
    }
    return (
      <div className="flex fs1 gap0">
        <span>No data</span>
        <TooltipComponent
          className="secondary-gray"
          title="No initial transcription data."
          placement="bottom"
          type="info-icon"
          showArrow
          color="secondary-gray"
          highlightOnHover={false}
          overlayStyle={{ minWidth: '100px' }}
        />
      </div>
    );
  };

  const bgGoal = () => {
    const bgGoalValue = cardInfo?.bgGoal?.a1c || 7.0;
    return (
      <div className="flex">
        {bgGoalValue}
        %
      </div>
    );
  };

  const expiredNote = (date: string) => (
    <TooltipComponent
      className="text-red"
      title={`The latest A1c on record was tested on ${date.replace(/\(|\)/g, '')}, which has passed the 3-month validity period. Please acquire an updated A1c.`}
      placement="bottom"
      type="info-icon"
      color="secondary-gray"
      showIconBefore
      overlayStyle={{ minWidth: '100px' }}
    >
      <span>Expired</span>
    </TooltipComponent>
  );

  const timeSection = (
    isBpMedian: boolean,
    latestA1cTime: string | null,
    otherTime: string | null,
    isA1cGoal: boolean
  ) => {
    if (isBpMedian && cardInfo?.bpStatData?.periodEnd) {
      return (
        <div>
          <span>(</span>
          Past month
          <span>)</span>
        </div>
      );
    }
    if (latestA1cTime) {
      const date = moment(latestA1cTime).format('(MM/DD/YYYY)');
      return moment(latestA1cTime).isBefore(moment().subtract(90, 'days'))
        ? expiredNote(date)
        : date;
    }
    if (otherTime) {
      return (
        moment(otherTime).format('(MM/DD/YYYY)')
      );
    }
    if (isA1cGoal) {
      return (
        <Button
          className="pl0 fs2"
          type="link"
          onClick={setOpenEditModal}
        >
          <EditOutlined
            className="patient-common-card-edit"
          />
          Edit
        </Button>
      );
    }
    return null;
  };

  const bpCardInfo = () => {
    if (cardInfo?.hasBp) {
      return (
        <div className="ControlLevelCardComponent__background">
          <div className="flex ai-c">
            <ConditionIconComponent
              condition={ConditionEnum.BLOOD_PRESSURE_MANAGEMENT}
              size={{ width: 14, height: 16 }}
            />
            <div className="title-style">
              Blood Pressure Indicators
            </div>
          </div>
          <div className="ControlLevelCardComponent__measurement-box">
            <div className="name-style">
              <div>
                BP Median
                <div className="value-style">
                  {bpMedian()}
                </div>
                <div className="text-gray-primary">
                  {timeSection(true, null, null, false)}
                </div>
              </div>
            </div>
            <Divider type="vertical" className="autoH" />
            <div className="name-style">
              <div className="ml12">
                BP Baseline
                <div className="value-style">
                  {bpBaseline()}
                </div>
                <div className="text-gray-primary">
                  {timeSection(false, null, cardInfo?.bpBaseline?.refEnd || null, false)}
                </div>
              </div>
            </div>
            <Divider type="vertical" className="autoH" />
            <div className="name-style">
              <div className="ml12">
                BP Goal
                <div className="value-style">
                  {bpGoal()}
                </div>
                <div>
                  {timeSection(false, null, null, false)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const bgCardInfo = () => {
    if (cardInfo?.hasBg) {
      return (
        <div className="ControlLevelCardComponent__background">
          <div className="flex">
            <ConditionIconComponent
              condition={ConditionEnum.GLUCOSE_MANAGEMENT}
              size={{ width: 14, height: 16 }}
            />
            <div className="title-style">
              Blood Glucose Indicators
            </div>
          </div>
          <div className="ControlLevelCardComponent__measurement-box">
            <div className="name-style">
              <div>
                Lastest A1c
                <div className="value-style">
                  {lastestA1C()}
                </div>
                <div className="pt5 text-gray-primary">
                  {timeSection(false, cardInfo?.bgStatData?.a1cDate || null, null, false)}
                </div>
              </div>
            </div>
            <Divider type="vertical" className="autoH" />
            <div className="name-style">
              <div className="ml12">
                A1c Baseline
                <div className="value-style">
                  {bgBaseline()}
                </div>
                <div className="pt5 text-gray-primary">
                  {timeSection(false, null, cardInfo?.bgBaseline?.a1cDate || null, false)}
                </div>
              </div>
            </div>
            <Divider type="vertical" className="autoH" />
            <div className="name-style">
              <div className="ml12">
                A1c Goal
                <div className="value-style">
                  {bgGoal()}
                </div>
                <div>
                  {timeSection(false, null, null, true)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const controlLevelModalBtn = () => (
    <Button
      type="text"
      onClick={setOpenTooltip}
      className="no-style-btn bg-white mb12 ml20 mt0"
    >
      <div className="underlined text-secondary-gray bg-transparent">
        <QuestionCircleOutlined className="mr10" />
        Understand Patient Control Level
      </div>
    </Button>
  );

  const unenrollText = () => {
    if (cardInfo?.hasBp === false && cardInfo?.hasBg === false) {
      return (
        <div className="ControlLevelCardComponent__background">
          Please encourage patient to enroll BP or BG vital.
        </div>
      );
    }
    return null;
  };

  return (
    <div className="ControlLevelCardComponent">
      {patientInfo()}
      {bpCardInfo()}
      {bgCardInfo()}
      {unenrollText()}
      {controlLevelModalBtn()}
      <A1CCustomizeGoalModalComponent
        patientId={patientId}
        initialValues={cardInfo?.bgGoal || undefined}
        open={isEditModalOpen}
        onCancel={setCloseEditModal}
        onSubmit={setCloseEditModal}
      />
      <PatientHealthConditionControlLevelModalComponent
        isOpen={isTooltipOpen}
        close={setCloseTooltip}
      />
    </div>
  );
};
