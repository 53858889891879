import { RcFile } from 'antd/lib/upload';
import { AxiosError } from 'axios';
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext, useState
} from 'react';
import {
  uniqBy,
  sortBy,
  slice
} from 'lodash';
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { useSendMessage } from '../../hooks/useSendMessage/useSendMessage';
import {
  MessageHistory, MessageHistoryMessage, MessageType
} from '../../services/CHSServices/types/data';
import {
  MessageACKPublisher, PatientMessageChannel
} from '../../types/message';
import { Patient } from '../../uc-api-sdk';
import { useLoggedInUserFromContext } from '../loggedInUserContext';
import { useMessageServicesContext } from './MessageServicesContext';
import { MAX_MSG_PER_CHANNEL } from '../../services/CHSServices/constants';
import { CHSServices } from '../../services/CHSServices/CHSServices';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import { PublishMessageResponseData } from '../../services/CHSServices/types';

export interface MessagePatientContextValue {
  draft?: string;
  file?: RcFile;
  setFile: (file?: RcFile) => void;
  setDraft: (value?: string) => void;
  patientId: string;
  patientChannel?: PatientMessageChannel;
  patientInfo?: Patient | null;
  isLoadingPatientChannel: boolean;
  getPatientChannelHistoryFirstTime: () => void;
  handleGetMorePatientMessages: (
    fromTimestamp?: string,
    count?: number,
  ) => void;
  publishACKMessage: () => ReturnType<ReturnType<typeof useSendMessage>['sendMessage']> | null;
  markChannelAsUnread: () => ReturnType<ReturnType<typeof useSendMessage>['sendMessage']> | null;
  handleSendMessage: (
    payload: MessageHistoryMessage['payload'],
    initChannel?: boolean,
  ) => Promise<PublishMessageResponseData>;
  scrollToBottom: (ref: MutableRefObject<Element | null>) => void;
  setNotificationTarget: (value: string) => void;
  setPatientActiveSubscription: (isAdded?: boolean) => void;
}

const MessagePatientContext = createContext<MessagePatientContextValue | undefined>(undefined);

export const useMessagePatientContext = () => {
  const context = useContext(MessagePatientContext);
  return (context || {}) as MessagePatientContextValue;
};

export interface MessagePatientContextProviderProps {
  children: ReactNode;
  patientId: string;
  patientInfo?: Patient | null;
}
export const MessagePatientContextProvider = ({
  children,
  patientId,
  patientInfo,
}: MessagePatientContextProviderProps) => {
  const {
    userInfo,
  } = useLoggedInUserFromContext();

  const {
    channelMap,
    userMap,
    handleSetUserMap,
    handleSetPatientMapData,
    handleGetChannelHistory,
    handleInitChannel,
    handleSetChannel,
    setNotificationTarget,
    setActiveSubscription,
    setOnGoingChannel,
  } = useMessageServicesContext();
  const patientChannel: MessagePatientContextValue['patientChannel'] = channelMap[patientId];
  const [isLoadingPatientChannel, setIsLoadingPatientChannel] = useState(false);
  const [
    patientHistoryMessages,
    setPatientHistoryMessages,
  ] = useState<MessageHistoryMessage[]>(patientChannel?.messages || []);
  const [
    patientChannelInfo,
    setPatientChannelInfo,
  ] = useState<Pick<PatientMessageChannel, 'hasMoreMessages' | 'fromTimestamp'>>({
    hasMoreMessages: false,
    fromTimestamp: undefined
  });
  const [draft, setDraft] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<RcFile | undefined>(undefined);

  const {
    sendMessage,
  } = useSendMessage({ patientId, patientInfo });

  const setPatientChannelHistory = (messages?: MessageHistoryMessage[]) => {
    setPatientHistoryMessages((prev) => {
      const nonEmptyMessages = messages || [];
      const hasMoreMessages = nonEmptyMessages.length === MAX_MSG_PER_CHANNEL;
      const combinedHistoryWithDatetime = [
        ...nonEmptyMessages,
        ...(prev || [])
      ].map((msg) => ({
        ...msg,
        dateTime: CHSServices.convertTimestamp(msg.timestamp),
      }));
      const dedupedHistoryMessages = uniqBy(combinedHistoryWithDatetime, (msg) => msg.dateTime);
      const readableMessages = CHSServices.getListOfReadableMessages(dedupedHistoryMessages);
      const sortedHistoryMessages = sortBy(readableMessages, (msg) => msg.dateTime);

      setPatientChannelInfo({
        hasMoreMessages,
        fromTimestamp: CHSServices.parseTimestamp(sortedHistoryMessages?.[0]?.dateTime),
      });

      setIsLoadingPatientChannel(false);

      return sortedHistoryMessages;
    });
  };

  const getPatientChannelHistory = useDebounce(async (
    fromTimestamp?: string,
    count = MAX_MSG_PER_CHANNEL,
  ): Promise<void> => {
    setIsLoadingPatientChannel(true);
    // fromTimestamp is to support load more message in the past
    const res = await handleGetChannelHistory({
      patientIds: [patientId],
      fromTimestamp,
      count,
    });
    const history = (res?.[0] || {}) as MessageHistory;
    setPatientChannelHistory(history?.messages);
    handleSetChannel(patientId, {
      ...history,
      messages: slice(history.messages, 0, 5),
    });
  }, 1000);

  const getPatientChannelHistoryFirstTime = async () => {
    getPatientChannelHistory();
    if (!userMap[patientId]) {
      handleSetUserMap(patientId, true);
    }
  };

  const handleGetMorePatientMessages: MessagePatientContextValue['handleGetMorePatientMessages'] = (
    fromTimestamp, // to fetch new message
    count = MAX_MSG_PER_CHANNEL,
  ) => {
    if (!fromTimestamp) {
      setPatientChannelInfo((prev) => ({
        ...prev,
        hasMoreMessages: false,
      }));
    }
    const fTimestamp = fromTimestamp || patientChannelInfo.fromTimestamp || '0';
    getPatientChannelHistory(fTimestamp, count);
  };

  const handleSendMessage: MessagePatientContextValue['handleSendMessage'] = async (
    payload,
    initChannel = true,
  ) => {
    if (initChannel) {
      await handleInitChannel(patientId);
      setOnGoingChannel(patientId);
    }
    let res;
    try {
      res = await sendMessage(payload);
      if (!res?.success) {
        throw new Error(res?.error || '');
      }
      setDraft(undefined);
      return res;
    } catch (error) {
      const axiosError = (error as AxiosError).response?.data as string;
      return {
        success: false,
        error: axiosError || res?.error || 'Failed to send message',
      };
    }
  };

  const publishACKMessage: MessagePatientContextValue['publishACKMessage'] = () => (
    patientChannel?.isUnread
      ? (
        handleSendMessage({
          type: MessageType.ACK,
          publisher: `${MessageACKPublisher.READ}-${userInfo?.id}`,
        }, false)
      )
      : null
  );

  const markChannelAsUnread: MessagePatientContextValue['markChannelAsUnread'] = () => (
    !patientChannel?.isUnread
      ? (
        handleSendMessage({
          type: MessageType.ACK,
          publisher: `${MessageACKPublisher.UNREAD}-${userInfo?.id}`,
        }, false)
      )
      : null
  );

  const scrollToBottom: MessagePatientContextValue['scrollToBottom'] = (
    ref,
  ) => {
    if (ref?.current) {
      ref.current.scrollIntoView();
    }
  };

  const setPatientActiveSubscription: MessagePatientContextValue['setPatientActiveSubscription'] = (isAdded = true) => setActiveSubscription?.(patientId, !!isAdded);

  useDeepCompareEffect(() => {
    if (patientInfo) {
      handleSetPatientMapData(patientInfo);
    }
  }, [patientInfo]);

  useDeepCompareEffect(() => {
    if (patientChannel?.temporaryMessages) {
      setPatientChannelHistory(patientChannel?.temporaryMessages);
    }
  }, [patientChannel?.temporaryMessages]);

  const contextValue = useGetContextValue<MessagePatientContextValue>({
    patientId,
    patientChannel: {
      ...patientChannel,
      patientId,
      lastMsg: CHSServices.getLastMessage(patientHistoryMessages),
      messages: patientHistoryMessages,
      hasMoreMessages: patientChannelInfo.hasMoreMessages,
    },
    patientInfo,
    isLoadingPatientChannel,
    getPatientChannelHistoryFirstTime,
    handleGetMorePatientMessages,
    publishACKMessage,
    markChannelAsUnread,
    handleSendMessage,
    scrollToBottom,
    draft,
    setDraft,
    file,
    setFile,
    setNotificationTarget,
    setPatientActiveSubscription,
  }, [
    patientHistoryMessages,
    patientChannelInfo?.hasMoreMessages,
  ]);

  return (
    <MessagePatientContext.Provider key={patientId} value={contextValue}>
      {children}
    </MessagePatientContext.Provider>
  );
};
