import { Space } from 'antd';
import { useMemo } from 'react';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { PatientInfo, usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ArticlesMainContainer } from '../../../articles/containers/ArticlesMainContainer';
import { BehaviorChangeContainer } from '../../../intervention/container/BehaviorChangeContainer/BehaviorChangeContainer';
import { BehaviorGoalContainer } from '../../../intervention/container/BehaviorGoalContainer/BehaviorGoalContainer';
import { InterventionVitalsMonitoringContainer } from '../../../intervention/container/InterventionVitalsMonitoringContainer/InterventionVitalsMonitoringContainer';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';
import { WorklistMTPRComponent } from '../WorklistMTPRComponent/WorklistMTPRComponent';
import { WorklistTabProps } from '../WorklistTabsComponent/type';
import { WorklistTabsComponent } from '../WorklistTabsComponent/WorklistTabsComponent';

export enum GoalEducationTabKey {
  Goals = 'Goals',
  Education = 'Education',
}

export interface GoalEducationComponentProps
  extends WorklistTabProps<GoalEducationTabKey> {
  patientId: string;
}

export const GoalEducationComponent = ({
  patientId,
  defaultActiveKey,
}: GoalEducationComponentProps) => {
  const { info } = usePatientContext();

  const items = useMemo(() => ([
    {
      key: GoalEducationTabKey.Goals,
      label: 'Goals',
      children: (
        <Space size="middle" direction="vertical" className="w100">
          <NestedFormComponent>
            <WorklistMTPRComponent
              patientId={patientId}
            />
          </NestedFormComponent>
          <NestedFormComponent>
            <WorklistContentComponent>
              <InterventionVitalsMonitoringContainer
                patientId={patientId}
                isEditing
                isInCharting
                showEdit={false}
                formButtons={null}
              />
            </WorklistContentComponent>
          </NestedFormComponent>
          <BehaviorChangeContainer
            info={info || {} as PatientInfo}
            showEdit={false}
            isInCharting
            isEditing
            showButtons={false}
            submitOnChange
          />
          <NestedFormComponent>
            <WorklistContentComponent>
              <BehaviorGoalContainer
                patientId={patientId}
                isEditing
                showEdit={false}
                formButtons={null}
              />
            </WorklistContentComponent>
          </NestedFormComponent>
        </Space>
      ),
    },
    {
      key: GoalEducationTabKey.Education,
      label: 'Education',
      children: (
        <PatientCommonCardComponent
          title="Search Articles to Patient's App"
          content={null}
          updateInfo={undefined}
          formContent={<ArticlesMainContainer title={null} />}
          showHeaderOutside={false}
          isEditing
          showEdit={false}
        />
      ),
    }
  ]), []);

  return (
    <WorklistTabsComponent
      defaultActiveKey={defaultActiveKey}
      items={items}
    />
  );
};
