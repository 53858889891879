import { Table } from 'antd';
import { FC, ReactNode, useEffect } from 'react';
import { useMedicationContext } from '../../../contexts/MedicationContext/MedicationContext';
import { MedicationType } from '../../../features/medication/type/type';
import { useTableColumns } from '../../../hooks/table/useTableColumns/useTableColumns';
import { createMedicationColumns } from '../columns';

export interface PrescribedMedicationsTableComponentProps {
  medications?: MedicationType[];
  addMedication?: ReactNode;
}

export const PrescribedMedicationsTableComponent: FC<PrescribedMedicationsTableComponentProps> = ({
  medications = [],
  addMedication,
}) => {
  const columnNames: (keyof typeof createMedicationColumns)[] = [
    'name',
    'type',
    'status',
    'dosageFrequency',
    'dosage',
    'instruction',
    'note',
    'reminderDisplay',
    'reminderWithSettings',
  ];
  const columns = useTableColumns(createMedicationColumns, columnNames);
  const { setCurMeds } = useMedicationContext();

  useEffect(() => {
    setCurMeds(medications.length);
  }, [medications.length]);

  if (medications.length === 0) {
    return <div />;
  }

  return (
    <div>
      <div className="flex jc-sb ai-c mb10">
        <div className="mb10 bold">Prescribed Medications</div>
        <div>{addMedication}</div>
      </div>
      <Table
        className="medicationsTable border default-border-radius"
        dataSource={medications}
        columns={columns}
        rowKey={(record) => (record.id)}
        pagination={medications.length > 10 ? {} : false}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};
