import { ControlLevelEnumComponent } from '../../../../enumComponent/ControlLevelEnumComponent/ControlLevelEnumComponent';
import { ControlLevelEnum } from '../../../../uc-api-sdk';
import './PatientHealthConditionControlLevelComponent.scss';
import { PatientHealthConditionControlLevelTableComponent } from './PatientHealthConditionControlLevelTableComponent';

export const PatientHealthConditionControlLevelDetailComponent = () => {
  const bpTableData = [
    {
      badge: (
        <div className="control-level-tag-wrapper">
          <ControlLevelEnumComponent value={ControlLevelEnum.CONTROLLED} />
        </div>
      ),
      description: (
        <span>
          Median BP &lt;140 mmHg
          {' '}
          <strong>and</strong>
          {' '}
          SBP &lt; 90 mmHg DBP
        </span>
      ),
    },
    {
      badge: (
        <div className="control-level-tag-wrapper">
          <ControlLevelEnumComponent value={ControlLevelEnum.UNCONTROLLED} />
        </div>
      ),
      description: (
        <>
          Median BP &gt;=140 mmHg
          {' '}
          <strong>or</strong>
          {' '}
          SBP &gt;= 90 mmHg DBP

        </>
      ),
    },
    {
      badge: (
        <div className="control-level-tag-wrapper">
          <ControlLevelEnumComponent value={ControlLevelEnum.UNCATEGORIZED} />
        </div>
      ),
      description: (
        <>
          Couldn't be classified due to insufficient data
        </>
      ),
    },
  ];

  const bgTableData = [
    {
      badge: (
        <div className="control-level-tag-wrapper">
          <ControlLevelEnumComponent value={ControlLevelEnum.CONTROLLED} />
        </div>
      ),
      description: (
        <>
          HbA1c &lt; 7% or customized goal
        </>
      ),
    },
    {
      badge: (
        <div className="control-level-tag-wrapper">
          <ControlLevelEnumComponent value={ControlLevelEnum.UNCONTROLLED} />
        </div>
      ),
      description: (
        <>
          HbA1c &gt;= 7% or customized goal
        </>
      ),
    },
    {
      badge: (
        <div className="control-level-tag-wrapper">
          <ControlLevelEnumComponent value={ControlLevelEnum.UNCATEGORIZED} />
        </div>
      ),
      description: (
        <>
          Couldn't be classified due to insufficient data
        </>
      ),
    },
  ];

  return (
    <div className="patient-health-condition-control-level-detail-component">
      <div className="intro">
        If a patient is at an uncontrolled status for
        {' '}
        <strong>either</strong>
        {' '}
        BP
        {' '}
        <strong>or</strong>
        {' '}
        BG, they will be classified as an uncontrolled
        patient.
      </div>
      <hr className="grey-separator" />

      {/* BP Table */}
      <div className="explanation-text">
        <strong>BP:</strong>
        {' '}
        <span className="explanation-content">
          Based on a minimum of 1 BP reading per calendar month.
        </span>
      </div>
      <PatientHealthConditionControlLevelTableComponent
        data={bpTableData}
      />

      {/* BG Table */}
      <div className="explanation-text">
        <strong>BG:</strong>
        {' '}
        <span className="explanation-content">
          Based on HbA1c from past 3 months.
        </span>
      </div>
      <PatientHealthConditionControlLevelTableComponent
        data={bgTableData}
      />
    </div>
  );
};
