import {
  Button, Divider, Form, Input
} from 'antd';
import { FormProps, useForm } from 'antd/lib/form/Form';
import { last } from 'lodash';
import { useMemo } from 'react';
import { USA_DATE } from '../../../../constants/timeFormat';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { Transcribe, VisitTypeEnum } from '../../../../uc-api-sdk';
import { CardTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardTextComponent/CardTextComponent';
import { CardTitleComponent } from '../../../../uiComponent/CardTypographyComponent/CardTitleComponent/CardTitleComponent';
import { ChronicDiagnosesNoteComponent } from '../../../../uiComponent/ChronicDiagnosesNoteComponent/ChronicDiagnosesNoteComponent';
import { MedicationChronicDiagnosesNoteComponent } from '../../../../uiComponent/ChronicDiagnosesNoteComponent/MedicationChronicDiagnosesNoteComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { TranscribeBasicInfoFormItemComponent } from '../../../../uiComponent/Transcribing/component/TranscribeBasicInfoFormItemComponent/TranscribeBasicInfoFormItemComponent';
import { TranscribeBasicInfoFormItemValues } from '../../../../uiComponent/Transcribing/component/TranscribeBasicInfoFormItemComponent/type';
import { ICDCodeFormItemComponent } from '../../../ICDCode/component/ICDCodeFormComponent/ICDCodeFormComponent';
import { DefaultICDCodeFormValues } from '../../../ICDCode/component/ICDCodeFormComponent/type';
import { InsuranceFormContainer } from '../../../Insurance/container/InsuranceFormContainer/InsuranceFormContainer';
import { useConfirmEligibilityEffect } from '../../../Insurance/hook/useConfirmEligibilityEffect';
import { useInsuranceBlockPrompt } from '../../../Insurance/hook/useInsuranceBlockPrompt';
import { MedicationManagementType } from '../../../medication/component/MedicationTabFormComponent/MedicationTabFormComponent';
import MedicationContainer from '../../../medication/container/MedicationContainer';
import { PatientHealthConditionComplexityFormItemComponent } from '../../../patient/component/PatientComplexityFormItemComponent/PatientHealthConditionComplexityFormItemComponent';
import { AddLabResultDrawerContainer } from '../../container/LabResultTableContainer/AddLabResultDrawerContainer';
import { BpBaselineFormComponentValues, BpBaselineFormItemComponent } from '../BpBaselineFormComponent/BpBaselineFormComponent';
import { TranscribeAddressFormItemComponent } from '../TranscribeAddressFormitemComponent/TranscribeAddressFormItemComponent';
import { TranscribeAddressFormItemValues } from '../TranscribeAddressFormitemComponent/type';
import { TranscribeInsuranceFormItemValues } from '../TranscribeInsuranceFormItemComponent/type';
import { TranscribeVisitCardComponent } from '../TranscribeVisitCardComponent/TranscribeVisitCardComponent';
import './TranscribeVisitDrawerFormComponent.scss';

export interface TranscribeVisitFormValues
  extends TranscribeBasicInfoFormItemValues,
  TranscribeAddressFormItemValues,
  TranscribeInsuranceFormItemValues,
  DefaultICDCodeFormValues,
  BpBaselineFormComponentValues,
  Omit<MedicationManagementType, 'extraNotes'> {
  transcribeNote?: string;
}

export interface TranscribeVisitDrawerFormComponentProps {
  patientId: string;
  transcribe?: Transcribe;
  onSubmit: (values: TranscribeVisitFormValues, completeTranscribe?: boolean) => void;
  submitText?: string;
  saveText?: string;
  showSaveForLaterButton?: boolean;
  onValuesChange?: FormProps['onValuesChange'];
  disabled?: boolean;
  showSubmitButton?: boolean;
  isLoading?: boolean;
}

export const TranscribeVisitDrawerFormComponent = ({
  patientId,
  transcribe,
  onSubmit,
  submitText = 'Complete Transcribing',
  saveText = 'Save',
  showSaveForLaterButton = true,
  onValuesChange,
  disabled,
  showSubmitButton = true,
  isLoading,
}: TranscribeVisitDrawerFormComponentProps) => {
  const { info } = usePatientContext();
  const { value: disableBaseLine, setTrue, setFalse } = useBoolean(false);
  const {
    patientInfoService, medicationManagementService,
    refetch, enrolledProgramService, controlLevelService
  } = info || {};
  const {
    healthConditionService,
    complexityService,
    patientInsurance,
  } = patientInfoService || {};
  const [form] = useForm();
  const {
    isInsuranceEdit,
    setIsInsuranceEdit,
    insuranceBlockPrompt
  } = useInsuranceBlockPrompt();
  // for confirm eligibility effect
  const confirmEligibilityEffect = useConfirmEligibilityEffect({
    initialPatientInfo: patientInfoService?.patient,
    initialInsuranceInfo: patientInsurance
  });
  useUpdateListener('INSURANCE_ELIGIBILITY_UPDATED', () => refetch?.(['patientInsuranceRefetch']));

  const handleOnFinish = useDebounce(async (
    values: TranscribeVisitFormValues,
  ) => {
    if (isInsuranceEdit) {
      insuranceBlockPrompt();
      return;
    }
    await confirmEligibilityEffect(values);
    onSubmit(values, true);
  }, 250, [onSubmit, isInsuranceEdit]);

  const showBaseLine = !controlLevelService?.hasBpBaseline()
    && transcribe?.visitType === VisitTypeEnum.INIT;

  const handleOnSave = useDebounce(async () => {
    try {
      const res = form.getFieldsValue();
      const shouldValidate = showBaseLine && !res.skipBpBaseline;
      if (shouldValidate) {
        const errors = await form.validateFields(['bpBaselineDate', 'systolic_blood_pressure', 'diastolic_blood_pressure', 'pulse']);
        if (errors.errorFields && errors.errorFields.length > 0) {
          return;
        }
      }
      await confirmEligibilityEffect(res);
      onSubmit(res as TranscribeVisitFormValues);
    } catch (error) {
      console.error(error);
    }
  }, 250, [form, onSubmit]);

  const initialValues: Partial<TranscribeVisitFormValues> = useMemo(() => ({
    firstName: patientInfoService?.firstName || '',
    lastName: patientInfoService?.lastName || '',
    dob: patientInfoService?.getBirthdayMoment(),
    nickName: patientInfoService?.nickName || '',
    providerId: patientInfoService?.getProviderId() as string,
    medicalId: patientInfoService?.getMedicalId() as string,
    spokenLanguage: patientInfoService?.getSpokenLanguage() as string[],
    weight: patientInfoService?.getWeightInLb() as number,
    height: patientInfoService?.getHeight() as number,
    mobilePhone: patientInfoService?.getMobilePhoneNumber() as string,
    homePhone: patientInfoService?.getHomePhoneNumber() as string,
    email: patientInfoService?.getEmail() as string,
    address: patientInfoService?.getAddress().address as string,
    address2: patientInfoService?.getAddress().address2 as string,
    city: patientInfoService?.getAddress().city as string,
    state: patientInfoService?.getAddress().state as string,
    zipcode: patientInfoService?.getAddress().zipcode as string,
    reconciliationCompliance: medicationManagementService?.reconciliationCompliance,
    reconciliationComplianceOther: medicationManagementService?.reconciliationComplianceOther,
    otherInfo: medicationManagementService?.otherInfo,
    medicationNote: medicationManagementService?.extraNote,
    transcribeNote: last(transcribe?.notes)?.note || '',
    icdTable: healthConditionService?.healthConditions,
    patientComplexity: complexityService?.patientComplexity,
  }), [
    transcribe?.notes,
    patientInfoService,
  ]);

  const handleOnValuesChange: FormProps['onValuesChange'] = (changedValues, values) => {
    if (changedValues.skipBpBaseline) {
      setTrue();
      form.resetFields(['bpBaselineDate', 'systolic_blood_pressure', 'diastolic_blood_pressure', 'pulse']);
    } else if (!changedValues.skipBpBaseline) {
      setFalse();
    }
    onValuesChange?.(changedValues, values);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleOnFinish}
      scrollToFirstError
      name="transcribeVisitDrawerForm"
      onValuesChange={handleOnValuesChange}
      initialValues={initialValues}
      disabled={isLoading || disabled}
    >
      <TranscribeVisitCardComponent
        updatedAt={info?.patientInfo?.updatedAt}
      >
        <div>
          <CardTitleComponent size="default">
            <span>Basic Information</span>
          </CardTitleComponent>
          <TranscribeBasicInfoFormItemComponent
            form={form}
            patientInfo={info}
          />
        </div>

        <div>
          <CardTitleComponent size="default">
            <span>Address</span>
          </CardTitleComponent>
          <TranscribeAddressFormItemComponent
            form={form}
          />
        </div>

        <div>
          <CardTitleComponent size="default">
            <span>Insurance</span>
          </CardTitleComponent>
          <InsuranceFormContainer
            patientInfo={patientInfoService?.patient || {}}
            onEdit={setIsInsuranceEdit}
          />
        </div>
      </TranscribeVisitCardComponent>

      {showBaseLine && (
        <TranscribeVisitCardComponent
          title="BP Baseline"
          updatedAt={null}
        >
          <BpBaselineFormItemComponent
            disabled={disableBaseLine}
            enrollmentDate={
              TimezoneService.calcDateTimeDayjs(
                enrolledProgramService?.getEnrollmentDate() || '',
                patientInfoService?.getTimezone()
              ).format(USA_DATE)
            }
            form={form}
          />
        </TranscribeVisitCardComponent>
      )}

      <TranscribeVisitCardComponent
        title="ICD Code*"
        updatedAt={info?.patientInfoService?.healthConditionService?.updatedAt}
      >
        <CardTextComponent>
          <ChronicDiagnosesNoteComponent />
        </CardTextComponent>
        <ICDCodeFormItemComponent
          form={form}
          disabled={disabled}
          required
        />
        <PatientHealthConditionComplexityFormItemComponent
          form={form}
          disabled={disabled}
        />
      </TranscribeVisitCardComponent>

      <TranscribeVisitCardComponent
        title="Medication"
        updatedAt={info?.patientInfo?.updatedAt}
      >
        <CardTextComponent>
          <MedicationChronicDiagnosesNoteComponent />
        </CardTextComponent>
        <MedicationContainer
          form={form}
          memberId={patientId}
          showSubmitButtons={false}
          showAdditionalNote={false}
          disabled={disabled}
        />
      </TranscribeVisitCardComponent>

      <TranscribeVisitCardComponent
        title="Lab Result"
      >
        <CardTextComponent>
          <ChronicDiagnosesNoteComponent />
        </CardTextComponent>
        <AddLabResultDrawerContainer
          patientId={patientId}
        />
      </TranscribeVisitCardComponent>
      <div className="transcribe-form-fixed-bottom mt30 w100">
        <Divider className="transcribe-form-divider" />
        <FormItem
          name="transcribeNote"
          id="transcribeNote"
        >
          <Input.TextArea
            placeholder="Leave notes"
            rows={2}
          />
        </FormItem>

        <div className="flex mt20">
          {
            showSubmitButton
              ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={disabled}
                >
                  {submitText}
                </Button>
              ) : null
          }
          {
            showSaveForLaterButton
              ? (
                <Button
                  type="link"
                  onClick={handleOnSave}
                  disabled={disabled}
                >
                  {saveText}
                </Button>
              ) : null
          }
        </div>
      </div>
    </Form>
  );
};
