import { DatePicker } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';

export interface PriorAuthSubmissionDateFormItemComponentProps {
  fieldName?: number;
}

export const PriorAuthSubmissionDateFormItemComponent = ({
  fieldName,
}: PriorAuthSubmissionDateFormItemComponentProps) => {
  const insuranceForm = useInsuranceForm();
  return (
    <FormItem
      name={insuranceForm.makeListNamePath('priorAuthSubmissionDate', fieldName)}
      label={insuranceForm.getLabel('priorAuthSubmissionDate')}
    >
      <DatePicker
        format="MM/DD/YYYY"
        placeholder="MM/DD/YYYY"
      />
    </FormItem>
  );
};
