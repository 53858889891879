import { useEffect, useState } from 'react';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { BehaviorChangeEnum, usePatientUpdate } from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { PatientBehaviorChangeEnumComponent } from '../../../../uiComponent/PatientBasicInfo/PatientBehaviorChangeEnumComponent/PatientBehaviorChangeEnumComponent';
import { PatientBehaviorChangeFormContentComponent } from '../../../patient/component/PatientBehaviorChangeFormContentComponent/PatientBehaviorChangeFormContentComponent';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { SubmitValue } from '../../component/LifestyleComponent/LifestyleFormComponent';
import { InterventionContainerProps } from '../../type';

export interface BehaviorChangeContainerProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError' | 'patientId'>,
  NestedFormComponentChildProps<SubmitValue> {
  info: PatientInfo;
  showButtons?: boolean;
  submitOnChange?: boolean;
  isInCharting?: boolean;
}

export const BehaviorChangeContainer = ({
  isEditing,
  onSubmit,
  onEdit,
  info,
  showEdit,
  onCancel,
  showButtons,
  isInCharting,
  submitOnChange
}: BehaviorChangeContainerProps) => {
  const { behaviorChangeInfo = {} } = info?.patientInfoService || {};
  const { behaviorChangeLevel } = behaviorChangeInfo;
  const updatePatientBehavior = usePatientUpdate({});
  const [newLevel, setNewLevel] = useState<BehaviorChangeEnum>(
    behaviorChangeLevel as BehaviorChangeEnum
  );

  const handleSubmit = useDebounce((newLevel) => {
    const req = updatePatientBehavior.send({
      params: {
        memberId: info.id,
        request: {
          profile: {
            behaviorChangeInfo: {
              behaviorChangeLevel: newLevel,
              isEdit: true,
            }
          }
        },
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Behavior Change Level updated successfully',
      error: 'Failed to update Behavior Change Level',
      onSuccess: () => {
        onSubmit?.();
        info.refetch(['patientRefetch']);
      },
    });
  }, 300, [newLevel]);

  const handleOnChange = (value: BehaviorChangeEnum) => {
    if (submitOnChange) {
      setNewLevel(value);
      handleSubmit(value);
    } else {
      setNewLevel(value);
    }
  };

  useEffect(() => {
    if (behaviorChangeLevel !== newLevel) {
      setNewLevel(behaviorChangeLevel as BehaviorChangeEnum);
    }
  }, [behaviorChangeLevel]);

  return (
    <PatientCommonCardComponent
      title="Stage of Behavior Change"
      isInCharting={isInCharting}
      showHeaderOutside={false}
      isEditing={isEditing}
      onEdit={onEdit}
      showEdit={showEdit}
      updateInfo={behaviorChangeInfo}
      content={(
        <PatientBehaviorChangeEnumComponent
          value={behaviorChangeLevel as BehaviorChangeEnum}
        />
      )}
      formContent={(
        <LoadingOverlayComponent
          isLoading={updatePatientBehavior.isLoading}
          showSkeleton
        >

          <PatientBehaviorChangeFormContentComponent
            value={newLevel}
            onChange={handleOnChange}
            onCancel={onCancel}
            onSubmit={() => handleSubmit(newLevel)}
            showButtons={showButtons}
          />
        </LoadingOverlayComponent>
      )}
    />
  );
};
