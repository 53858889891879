import { map } from 'lodash';
import dayjs from 'dayjs';
import {
  ComplianceAlert,
  SmartAlertConfig,
  SmartAlertTaskStatus,
  SnoozeIntervalEnum,
  useComplianceAlertCloseSmartAlertById,
  usePatientGetPatientAlertConfig,
  usePatientSetPatientAlertTasksConfig,
  useComplianceAlertGetComplianceAlertByMemberId,
  useMedicalAlertGetAlertsByMemberId,
  useComplianceAlertSearch,
  useComplianceAlertUpdate,
} from '../../../uc-api-sdk';
import { AlertSnoozeSubmitValue } from '../component/AlertComponent/AlertSnoozeFormComponent';
import { getSnoozeIntervalDate } from '../constant/constants';
import { AlertFormSubmitValue } from '../component/AlertComponent/AlertFormComponent';
import { DATE_TIME_CALENDAR } from '../../../constants/timeFormat';
import TimezoneService from '../../../helpers/timezone/timezoneService';

export const getCompliance = (patientId: string) => {
  const info = useComplianceAlertGetComplianceAlertByMemberId({
    options: {
      sendOnMount: true,
    },
    params: {
      memberId: patientId,
    },
  });
  return info;
};

export const getMedical = (patientId: string) => {
  const info = useMedicalAlertGetAlertsByMemberId({
    options: {
      sendOnMount: true,
    },
    params: {
      memberId: patientId,
    },
  });
  return info;
};

/**
 * @deprecated The method should not be used
 */
export const snooze = () => {
  const { send, ...rest } = usePatientSetPatientAlertTasksConfig({});
  const sendReq = (
    isValid: boolean,
    patientId?: string,
    task?: ComplianceAlert[],
    value?: AlertSnoozeSubmitValue,
  ) => {
    const configs = map(task, (aTask) => {
      const config: SmartAlertConfig = {
        smartAlertType: aTask.alertType,
        snoozeInterval: value?.snoozeInterval,
        alertDisplay: value?.snoozeReason,
        startDate: dayjs().format(DATE_TIME_CALENDAR),
        endDate: getSnoozeIntervalDate(value?.snoozeInterval ?? SnoozeIntervalEnum.ONE_DAY)
          .format(DATE_TIME_CALENDAR),
        isValid,
        priorityLevel: aTask.priority,
      };
      return config;
    });
    return send({
      params: {
        configRequest: {
          memberId: patientId,
          configs,
        }
      },
    });
  };
  return { ...rest, send: sendReq };
};

export const getSnoozedAlerts = (patientId: string) => {
  const info = usePatientGetPatientAlertConfig({
    options: {
      sendOnMount: true,
    },
    params: {
      memberId: patientId,
    },
  });
  return info;
};

export const getMutedAlerts = (patientId: string) => {
  const info = useComplianceAlertSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      filter: {
        memberId: patientId,
        status: SmartAlertTaskStatus.MUTE,
      },
    },
  });
  return info;
};

export const closeAlerts = () => {
  const { send, ...rest } = useComplianceAlertCloseSmartAlertById({});
  const sendReq = (
    alerts: ComplianceAlert[],
    comment: string,
  ) => Promise.all(
    map(alerts, (s) => {
      if (s.id && comment) {
        return send({
          params: {
            resolveAlertRequest: {
              alertIds: [s.id],
              note: comment,
            }
          }
        });
      }
      return undefined;
    })
  );
  return { ...rest, send: sendReq };
};

export const muteAlerts = () => {
  const { send, ...rest } = useComplianceAlertUpdate({});
  const sendReq = (
    alerts: ComplianceAlert[],
    value?: AlertSnoozeSubmitValue,
  ) => Promise.all(
    map(alerts, (s) => {
      if (s.id) {
        return send({
          params: {
            id: s.id,
            document: {
              status: SmartAlertTaskStatus.MUTE,
              snoozeInterval: value?.snoozeInterval ?? undefined,
              snoozeReason: value?.snoozeReason ?? undefined,
              snoozedDate: TimezoneService.getUTC(dayjs()),
            }
          }
        });
      }
      return undefined;
    })
  );
  return { ...rest, send: sendReq };
};

export const unMuteAlerts = () => {
  const { send, ...rest } = useComplianceAlertUpdate({});
  const sendReq = (
    alerts: ComplianceAlert[],
  ) => Promise.all(
    map(alerts, (s) => {
      if (s.id) {
        return send({
          params: {
            id: s.id,
            document: {
              status: SmartAlertTaskStatus.DONE,
            }
          }
        });
      }
      return undefined;
    })
  );
  return { ...rest, send: sendReq };
};

export const commentAlerts = () => {
  const { send, ...rest } = useComplianceAlertUpdate({});
  const sendReq = (
    value?: AlertFormSubmitValue,
  ) => Promise.all(
    map(value?.alerts, (s) => {
      if (s.id && value?.comment) {
        return send({
          params: {
            id: s.id,
            document: {
              notes: [{ note: value.comment }],
            },
          }
        });
      }
      return undefined;
    })
  );
  return { ...rest, send: sendReq };
};
