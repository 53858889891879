import {
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';
import {
  difference,
  filter,
  forEach,
  intersection,
  map,
  some,
  sortBy,
  uniq
} from 'lodash';
import { message } from 'antd';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import {
  MessageChannel,
  MessageChannelMap,
  MessageUserInfo,
  MessageUserMap,
} from '../../types/message';
import {
  MessageHistory,
  MessageHistoryMessage,
} from '../../services/CHSServices/types/data';
import { useGetNotificationChannelGroup } from '../../services/CHSServices/hooks/useGetNotificationChannelGroup';
import { useLoggedInUserFromContext } from '../loggedInUserContext';
import { useGetPaginatedChannels } from '../../services/CHSServices/hooks/useGetPaginatedChannels';
import { useGetChannelHistory } from '../../services/CHSServices/hooks/useGetChannelHistory';
import {
  GetChannelHistoryParams,
  MessageEventType,
  PaginatedChannelsParams,
  SubscriptionMessagePayload,
} from '../../services/CHSServices/types';
import { UNKNOWN_VALUE } from '../../services/CHSServices/constants';
import { CHSServices, chsServices } from '../../services/CHSServices/CHSServices';
import {
  Patient,
  RoleTypeEnum,
  useEmployeeSearch,
  usePatientSearch,
} from '../../uc-api-sdk';
import EmployeeInfo from '../../hooks/useUserInfo/employeeInfo';
import { Employee } from '../../types/user';
import { useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';
import { useInitChannel } from '../../services/CHSServices/hooks/useInitChannel';
import { MessageStorageContextProvider } from './MessageStorageContext';
import { useMixpanelContext } from '../MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../MixpanelContext/MixpanelEvents';

// true: target all, fetch channel history for all notification
// false: target none, add placeholder channel only
// string: target specific patient, fetch and update channel history
type NotificationTarget = string | boolean;

export interface MessageServicesContextValue {
  channelMap: MessageChannelMap;
  getChannel: (patientId?: string) => MessageChannel | undefined;
  userMap: MessageUserMap;
  loggedInUserHasUnread: boolean;
  handleSetChannel: (
    patientId: string,
    messageHistory: MessageHistory,
    isOngoing?: boolean,
  ) => void;
  handleSetUserMap: (userId: string, isPatient: boolean) => void;
  handleSetMultiplePatientMap: (patientIds: string[],) => void;
  handleSetMultipleUserMap: (patientIds: string[], employeeIds?: string[]) => void;
  handleSetPatientMapData: (patientInfo: Patient) => void;
  patientSearchLoading: boolean;
  paginatedChannelsLoading: boolean;
  getChannelHistoryLoading: boolean;
  handleGetNotificationChannelGroup: () => ReturnType<ReturnType<typeof useGetNotificationChannelGroup>['send']>;
  handleGetPaginatedChannels: (params: PaginatedChannelsParams) => ReturnType<ReturnType<typeof useGetPaginatedChannels>['send']>;
  handleGetUnreadPaginatedChannels: (params?: PaginatedChannelsParams,) => void;
  handleGetChannelHistory: (params: GetChannelHistoryParams) => ReturnType<ReturnType<typeof useGetChannelHistory>['send']>;
  handleInitChannel: (patientId: string) => ReturnType<ReturnType<typeof useInitChannel>['send']>;
  checkChannelHasUnread: (patientId: string) => boolean;
  handleMessageNotification: (messagePayload: SubscriptionMessagePayload) => void;
  setNotificationTarget: (target: NotificationTarget) => void;
  setActiveSubscription: (patientId: string, isAdded?: boolean) => void;
  setOnGoingChannel: (patientId: string) => void;
}

const MessageServicesContext = createContext<MessageServicesContextValue | undefined>(undefined);

export const useMessageServicesContext = () => {
  const context = useContext(MessageServicesContext);
  return (context || {}) as MessageServicesContextValue;
};

export interface MessageServicesContextProviderProps {
  children: ReactNode;
}
export const MessageServicesContextProvider = ({
  children,
}: MessageServicesContextProviderProps) => {
  const {
    loginInfo,
    userInfo,
    userId,
  } = useLoggedInUserFromContext();
  const { send } = useMixpanelContext();

  const [channelMap, setChannelMap] = useState<MessageServicesContextValue['channelMap']>({});
  const [userMap, setUserMap] = useState<MessageServicesContextValue['userMap']>({});
  const [notificationTarget, _setNotificationTarget] = useState<NotificationTarget>(false);
  const [activeSubscriptions, setActiveSubscriptions] = useState<string[]>([]);

  const setActiveSubscription: MessageServicesContextValue['setActiveSubscription'] = (
    patientId,
    isAdded,
  ) => {
    setActiveSubscriptions((prev) => {
      if (isAdded) {
        return uniq([...prev, patientId]);
      }
      return filter(prev, (p) => p !== patientId);
    });
  };

  const setNotificationTarget = (target: NotificationTarget) => {
    if (target === true) {
      _setNotificationTarget(true);
      return;
    }
    if (typeof target === 'string') {
      if (notificationTarget === true) {
        // do nothing, target=true has higher privilege
        return;
      }
      if (!target) {
        _setNotificationTarget(false);
        return;
      }
    }
    // target is patientId or false
    _setNotificationTarget(target);
  };

  const setOnGoingChannel = (patientId: string) => {
    setChannelMap((prevMap) => {
      const oldChannelInfo = prevMap[patientId] || {};
      return {
        ...prevMap,
        [patientId]: {
          ...oldChannelInfo,
          isOngoing: true,
        },
      };
    });
  };

  const employeeSearchInfo = useEmployeeSearch({
    options: { sendOnMount: false },
  });
  const patientSearchInfo = usePatientSearch({});

  const notificationChannelGroupInfo = useGetNotificationChannelGroup();
  const paginatedChannelsInfo = useGetPaginatedChannels();
  const getChannelHistoryInfo = useGetChannelHistory();
  const initChannelInfo = useInitChannel();

  const processChannel = (
    patientId: string,
    messageHistory: MessageHistory,
    oldChannelInfo = {} as MessageChannel,
  ): MessageChannel => {
    try {
      const {
        messages,
        lastClientACK,
      } = messageHistory;

      const sortedMessages = sortBy(messages, (msg) => msg.timestamp);

      const {
        isUnread,
        unreadMessages,
        lastMsg
      } = chsServices.handleUnreadAndLastMsg({
        ...messageHistory,
        messages: sortedMessages,
      });

      return {
        ...oldChannelInfo,
        patientId,
        isUnread,
        unreadMessages,
        lastMsg,
        messages,
        lastClientACK,
        temporaryMessages: messages,
        isPlaceholder: false,
        newNotification: undefined,
      } as MessageChannel;
    } catch (error) {
      send({
        event: MixpanelEvents.MessageError,
        properties: {
          error,
          patientId,
          messageHistory,
        },
      });
      return { ...oldChannelInfo };
    }
  };

  const handleSetUnreadChannels = (
    patientIds: string[],
  ) => {
    setChannelMap((prevMap) => {
      const newChannelInfoList = {} as MessageChannelMap;
      forEach(patientIds, (patientId) => {
        const oldChannelInfo = prevMap[patientId] || {};

        newChannelInfoList[patientId] = {
          ...oldChannelInfo,
          patientId,
          isUnread: true,
          isPlaceholder: true,
        };
      });
      return {
        ...prevMap,
        ...newChannelInfoList
      };
    });
  };

  const handleSetChannel: MessageServicesContextValue['handleSetChannel'] = (
    patientId,
    messageHistory,
    isOngoing,
  ) => {
    setChannelMap((prevMap) => {
      if (!patientId) return prevMap;

      const patientChannel = processChannel(
        patientId,
        messageHistory,
        prevMap[patientId],
      );

      if (isOngoing !== undefined) {
        patientChannel.isOngoing = isOngoing;
      }

      return {
        ...prevMap,
        [patientId]: patientChannel,
      };
    });
  };

  const processPatientUserMap = (
    patient: Patient,
  ) => ({
    firstName: patient.profile?.firstName || UNKNOWN_VALUE,
    lastName: patient.profile?.lastName || UNKNOWN_VALUE,
    avatar: patient.profile?.avatar?.thumbnailLink,
  });

  const processEmployeeUserMap = (
    employee: Employee,
  ) => {
    const employeeInfo = new EmployeeInfo({ employee });
    return {
      firstName: employeeInfo.profile?.firstName || UNKNOWN_VALUE,
      lastName: employeeInfo.profile?.lastName || UNKNOWN_VALUE,
      avatar: employeeInfo?.avatar,
    };
  };

  const handleSetUserMap: MessageServicesContextValue['handleSetUserMap'] = async (
    userId,
    isPatient,
  ) => {
    if (!userId || userMap[userId]) return;
    try {
      let userInfo = {} as MessageUserInfo;
      if (isPatient) {
        const res = await patientSearchInfo.send({
          params: {
            filter: { id: userId },
            pageInfo: { pagination: false },
          },
        });
        if (!res?.data || res.code !== 200) {
          throw new Error('Failed to get patient info');
        }
        const patient = res.data?.content?.[0] || {};
        userInfo = processPatientUserMap(patient);
      } else if (!userMap[userId]) {
        const res = await employeeSearchInfo.send({
          params: {
            filter: { id: userId },
            pageInfo: { pagination: false }
          },
        });
        if (!res?.data || res.code !== 200) {
          throw new Error('Failed to get patient info');
        }
        userInfo = processEmployeeUserMap(res.data as Employee);
      }
      if (Object.keys(userInfo).length > 0) {
        setUserMap((prevMap) => ({
          ...prevMap,
          [userId]: userInfo,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetMultiplePatientMap: MessageServicesContextValue['handleSetMultiplePatientMap'] = async (
    patientIds,
  ) => {
    if (!patientIds?.length) return;
    const patientIdsToSearch = difference(patientIds, Object.keys(userMap));
    const res = await patientSearchInfo.send({
      params: {
        filter: { idIn: { in: patientIdsToSearch } },
        pageInfo: { pagination: false }
      },
    });
    if (!res?.data || res.code !== 200) {
      console.error('Failed to get patient info', res?.msg);
      return;
    }
    const patients = res.data?.content || [];
    const patientsUserMap = Object.assign({}, ...patients.map((p) => ({
      [p.id as string]: processPatientUserMap(p),
    })));
    setUserMap((prevMap) => ({
      ...prevMap,
      ...patientsUserMap,
    }));
  };

  const handleSetMultipleEmployeeMap = async (
    employeeIds?: string[],
  ) => {
    if (!employeeIds || !employeeIds.length) return;
    const employeeIdsToSearch = difference(employeeIds, Object.keys(userMap));
    const res = await employeeSearchInfo.send({
      params: {
        filter: { idIn: { in: employeeIdsToSearch } },
        pageInfo: { pagination: false },
      }
    });
    if (!res?.data || res.code !== 200) {
      console.error('Failed to get patient info', res?.msg);
      return;
    }
    const employees = res.data?.content || [];
    const employeeUserMap = Object.assign({}, ...employees.map((e) => ({
      [e.id as string]: processEmployeeUserMap(e as Employee),
    })));
    setUserMap((prevMap) => ({
      ...prevMap,
      ...employeeUserMap,
    }));
  };

  const handleSetMultipleUserMap: MessageServicesContextValue['handleSetMultipleUserMap'] = (
    patientIds,
    employeeIds,
  ) => {
    handleSetMultiplePatientMap(patientIds);
    handleSetMultipleEmployeeMap(employeeIds);
  };

  const handleSetPatientMapData: MessageServicesContextValue['handleSetPatientMapData'] = (
    patientInfo,
  ) => {
    if (!patientInfo?.id) return;
    setUserMap((prevMap) => ({
      ...prevMap,
      [patientInfo.id as string]: processPatientUserMap(patientInfo),
    }));
  };

  const handleGetNotificationChannelGroup: MessageServicesContextValue['handleGetNotificationChannelGroup'] = () => (
    notificationChannelGroupInfo.send({
      params: { authKey: loginInfo?.chatInfo?.authKey || '' },
    })
  );

  const handleGetPaginatedChannels: MessageServicesContextValue['handleGetPaginatedChannels'] = (
    params,
  ) => (
    paginatedChannelsInfo.send({
      params,
    })
  );

  const handleGetChannelHistory: MessageServicesContextValue['handleGetChannelHistory'] = (
    params,
  ) => {
    const {
      patientIds = [],
      count = 1,
      fromTimestamp,
      ignoreACK = true,
    } = params || {};
    if (!patientIds?.length) return Promise.resolve([]);
    return getChannelHistoryInfo.send({
      params: {
        count,
        patientIds,
        origin: 'new',
        ignoreACK,
        fromTimestamp,
      },
    });
  };

  const handleInitChannel: MessageServicesContextValue['handleInitChannel'] = async (
    patientId,
  ) => {
    // response:
    // init=true, fresh=true => no channel before + init successfully
    // init=false, fresh = false/true => had channel before
    // init=true, fresh=false => issue with server
    const res = await initChannelInfo.send({
      params: {
        patientId,
        userId: userId || '',
      }
    });
    if (
      res
      && res.initChannel
      && !res.freshSubscription
    ) {
      message.error('Failed to subscribe the channel. Please refresh the page again.');
      return undefined;
    }
    return res;
  };

  const getChannel: MessageServicesContextValue['getChannel'] = (
    patientId,
  ) => (
    patientId ? channelMap[patientId] : undefined
  );

  const checkChannelHasUnread: MessageServicesContextValue['checkChannelHasUnread'] = (patientId) => {
    const redDotFlag = true;
    const {
      isUnread,
    } = channelMap[patientId] || {};
    return redDotFlag && !!isUnread;
  };

  const handleGetUnreadPaginatedChannels: MessageServicesContextValue['handleGetUnreadPaginatedChannels'] = async (
    params,
  ) => {
    const {
      toTimestamp,
      fromTimestamp,
      ...restParams
    } = params || {};
    // get channels
    const res = await handleGetPaginatedChannels({
      toTimestamp: toTimestamp ? CHSServices.parseTimestamp(toTimestamp) : undefined,
      fromTimestamp: fromTimestamp ? CHSServices.parseTimestamp(fromTimestamp) : undefined,
      pageSize: undefined,
      ...restParams,
      unread: true,
    });
    const {
      Channels = [],
    } = res || {};

    const patientIds = Channels?.map((c) => c.split('-')[1]);
    handleSetUnreadChannels(patientIds);
  };

  const loggedInUserHasUnread = useDeepCompareMemo(() => {
    const patientIds = map(channelMap, 'patientId');
    const isRDHC = intersection(
      [RoleTypeEnum.RD, RoleTypeEnum.HC],
      userInfo?.allRoleTypes
    ).length > 0;
    return isRDHC && some(patientIds, checkChannelHasUnread);
  }, [channelMap]);

  const handleMarkChannelHasNotification = (
    patientId: string,
  ) => {
    setChannelMap((prevMap) => ({
      ...prevMap,
      [patientId]: {
        ...(prevMap[patientId] || {}),
        patientId,
        isPlaceholder: true,
      } as MessageChannel,
    }));
  };

  const getSetChannelHistoryForNotification = (
    patientId: string,
    isACKNotification: boolean,
  ) => {
    setTimeout(async () => {
      const res = await handleGetChannelHistory({
        patientIds: [patientId],
        count: 1,
      });
      if (!res) return;
      const data = res[0] || {};
      const {
        messages,
      } = data;
      if (messages.length) {
        const lastMessageInHistory = messages?.[0] || {} as MessageHistoryMessage;
        if (
          !isACKNotification
          && chsServices.shouldExcludeMessage(lastMessageInHistory.payload)
        ) {
          return;
        }
        handleSetChannel(patientId, data);
      }
    }, 350);
  };

  const handleSetChannelHasNewNotification = (
    patientId: string,
    isACK: boolean,
  ) => {
    setChannelMap((prevMap) => {
      const oldChannelInfo = prevMap[patientId] || {};
      return {
        ...prevMap,
        [patientId]: {
          ...oldChannelInfo,
          patientId,
          newNotification: {
            receivedAt: Date.now(),
            isACK,
          }
        } as MessageChannel,
      };
    });
  };

  const handleMessageNotification: MessageServicesContextValue['handleMessageNotification'] = (
    payload,
  ) => {
    const {
      type,
      publisher,
      patient: patientId,
    } = payload || {};
    if (!patientId) {
      return;
    }
    const isPatientMessage = publisher === patientId;
    const isACKNotification = type === MessageEventType.ACK_NOTIFICATION;
    if (
      (notificationTarget === true && activeSubscriptions?.includes(patientId))
      // OR target specific patient => fetch history only for that channel
      || (typeof notificationTarget === 'string'
        && notificationTarget === patientId)
    ) {
      // let history component fetch new message
      handleSetChannelHasNewNotification(patientId, isACKNotification);
      return;
    }
    if (
      notificationTarget === true
    ) {
      // target all => fetch history for any channel
      getSetChannelHistoryForNotification(
        patientId,
        isACKNotification,
      );
      // fetch publisher info
      handleSetUserMap(publisher, !!isPatientMessage);
      return;
    }
    // target none => add placeholder channel IFF message is from patient
    if (isPatientMessage) {
      if (isACKNotification) {
        // ignore patient's ACK message if no target
        return;
      }

      handleSetUnreadChannels([patientId]);
      return;
    }
    handleMarkChannelHasNotification(patientId);
  };

  const contextValue = useGetContextValue<MessageServicesContextValue>({
    channelMap,
    getChannel,
    userMap,
    handleSetChannel,
    handleSetUserMap,
    handleSetMultiplePatientMap,
    handleSetMultipleUserMap,
    patientSearchLoading: patientSearchInfo.isLoading,
    paginatedChannelsLoading: paginatedChannelsInfo.isLoading,
    getChannelHistoryLoading: getChannelHistoryInfo.isLoading,
    handleGetNotificationChannelGroup,
    handleGetPaginatedChannels,
    handleGetUnreadPaginatedChannels,
    handleGetChannelHistory,
    handleInitChannel,
    checkChannelHasUnread,
    handleSetPatientMapData,
    loggedInUserHasUnread,
    handleMessageNotification,
    setNotificationTarget,
    setActiveSubscription,
    setOnGoingChannel,
  }, [
    patientSearchInfo.isLoading,
    paginatedChannelsInfo.isLoading,
    getChannelHistoryInfo.isLoading,
  ]);

  return (
    <MessageServicesContext.Provider value={contextValue}>
      <MessageStorageContextProvider>
        {children}
      </MessageStorageContextProvider>
    </MessageServicesContext.Provider>
  );
};
