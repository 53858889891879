import { ReactNode } from 'react';
import { TableContextProvider, useTableContext } from '../../../../contexts/TableContext/TableContext';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import { PatientCareDetail } from '../../../../uc-api-sdk';
import { PatientCareFilter, patientCareFilterProcessor } from '../../hook/usePatientCareTableOnChange';

export interface PatientCareTableContextProps {
  children: ReactNode;
}

export const PatientCareTableContext = ({
  children,
}: PatientCareTableContextProps) => (
  <TableContextProvider<PatientCareDetail, PatientCareFilter>
    params={{
      cachePagination: false,
      name: TableCacheNameEnum.PatientCare,
      sortProcessor: (sorter) => sorterHelper(sorter),
      filterProcessor: patientCareFilterProcessor
    }}
  >
    {children}
  </TableContextProvider>
);

export const usePatientCareTableContext = () => {
  const value = useTableContext<PatientCareDetail, PatientCareFilter>();
  return value;
};
