import { ColumnType } from 'antd/es/table';
import moment, { Moment } from 'moment';
import { map, startCase, toLower } from 'lodash';
import dayjs from 'dayjs';
import { MEDICATION_DATE_FORMAT } from '../../constants/timeFormat/index';
import { MED_TYPES } from '../../selectComponents/MedicationTypeSelectComponent/constants';
import { MED_STATUS } from '../../uiComponent/Medication/constants';
import { MedicationNameRendererComponent } from './MedicationNameRendererComponent/MedicationNameRendererComponent';
import { MedicationTableSettingsComponent } from './MedicationTableSettingsComponent/MedicationTableSettingsComponent';
import { EditMedicationDrawerComponent } from '../../features/medication/component/EditMedicationDrawerComponent/EditMedicationDrawerComponent';
import { DisplayDateComponent } from '../../uiComponent/DisplayComponent/DisplayDateComponent';
import { DelimitedListComponent } from '../../uiComponent/DelimitedListComponent/DelimitedListComponent';
import { DosageFrequencyEnumComponent } from '../../enumComponent/DosageFrequencyEnumComponent/DosageFrequencyEnumComponent';
import { FrequencyDisplayType, Medication } from '../../uc-api-sdk';
import { MedicationType } from '../../features/medication/type/type';
import { isDosingFrequencySupported } from '../../hooks/useMedicationForm/useMedicationForm';
import { MedicationInstructionEnumComponent } from '../../enumComponent/MedicationInstructionEnumComponent/MedicationInstructionEnumComponent';

type dateType = Moment | string | null | undefined;

const dateSorter = (a: dateType, b: dateType) => {
  if (a && b) {
    return moment(a).unix() - moment(b).unix();
  } if (!a) {
    return -1;
  }
  return 1;
};

export const createMedicationColumns = {
  name: (): ColumnType<Medication> => ({
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 160,
    fixed: 'left',
    sorter: (a, b) => String(a.name)?.localeCompare(String(b.name)),
    render: (a, b) => (
      <MedicationNameRendererComponent
        name={b.name || ''}
        otherMD={b.otherMD || false}
      />
    ),
  }),
  type: (): ColumnType<Medication> => ({
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    filters: MED_TYPES,
    onFilter: (value, record) => record.type?.toLowerCase()
      === String(value).toLowerCase(),
    render: (v) => startCase(toLower(String(v))),
  }),
  status: (): ColumnType<Medication> => ({
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    filters: MED_STATUS,
    onFilter: (value, record) => record.status?.toLowerCase()
      === String(value).toLowerCase(),
    render: (v) => (v ? startCase(toLower(String(v).replace('_', ' '))) : null),
  }),
  dosageFrequency: (): ColumnType<Medication> => ({
    title: 'Dosing Frequency',
    dataIndex: 'frequency',
    key: 'frequency',
    width: 150,
    render: (v) => (
      <DosageFrequencyEnumComponent
        value={v}
        type="long"
      />
    ),
    filters: map(FrequencyDisplayType, (v) => ({
      text: <DosageFrequencyEnumComponent value={v} type="long" />,
      value: v,
    })),
    onFilter: (value, record) => record?.frequency === value,
  }),
  dosage: (): ColumnType<Medication> => ({
    title: 'Dosage',
    dataIndex: 'dosage',
    width: 100,
    key: 'dosage',
  }),
  instruction: (): ColumnType<Medication> => ({
    title: 'Instruction',
    dataIndex: 'instruction',
    key: 'instruction',
    width: 100,
    render: (v) => (
      <MedicationInstructionEnumComponent
        value={v}
      />
    ),
  }),
  startDate: (): ColumnType<Medication> => ({
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    width: 140,
    sorter: (a, b) => dateSorter(a.startDate, b.startDate),
    render: (v) => (v ? moment(v).format(MEDICATION_DATE_FORMAT) : ''),
  }),
  endDate: (): ColumnType<Medication> => ({
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    width: 140,
    sorter: (a, b) => dateSorter(a.endDate, b.endDate),
    render: (v) => (v ? moment(v).format(MEDICATION_DATE_FORMAT) : ''),
  }),
  note: (): ColumnType<Medication> => ({
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 200,
  }),
  settings: (): ColumnType<MedicationType> => ({
    title: '',
    key: 'settings',
    dataIndex: 'id',
    width: 80,
    render: (v, record) => (
      <MedicationTableSettingsComponent
        medId={v}
        record={record}
      />
    ),
  }),
  reminderWithSettings: (): ColumnType<MedicationType> => ({
    title: 'Action',
    key: 'reminderWithSettings',
    dataIndex: 'id',
    width: 80,
    fixed: 'right',
    render: (v, record) => {
      const hasReminder = record?.reminders?.length;
      const isFrequencySupported = isDosingFrequencySupported(record?.frequency);
      return (
        <div className="flex ai-c">
          <EditMedicationDrawerComponent
            medication={record}
            buttonText={(
              hasReminder
                ? 'Edit Reminder'
                : 'Add Reminder'
            )}
            buttonProps={{ type: 'link' }}
            autoEnableReminder={isFrequencySupported && !hasReminder}
          />
          <MedicationTableSettingsComponent
            medId={v}
            record={record}
          />
        </div>
      );
    },
  }),
  reminderDisplay: (): ColumnType<MedicationType> => ({
    title: 'Reminder',
    key: 'reminderDisplay',
    dataIndex: 'reminders',
    width: 125,
    render: (v, record) => (
      <DelimitedListComponent
        className="flex gap0 f-w"
        list={record.reminders}
        render={(reminder) => (
          <DisplayDateComponent
            key={`${reminder.hour}-${reminder.minute}`}
            value={(
              dayjs().startOf('day')
                .add(reminder.hour ?? 0, 'hours')
                .add(reminder.minute ?? 0, 'minutes')
            )}
            format="hhmm_a"
          />
        )}
      />
    ),
  }),
};
