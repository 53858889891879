import { FilterValue } from 'antd/lib/table/interface';
import React, {
  FC, useCallback, useContext, useMemo, useState
} from 'react';
import { isEmpty } from 'lodash';
import { InFilter, SortInfo } from '../../uc-api-sdk';

export type FiltersValueType = Record<string, FilterValue | boolean | InFilter<string> | null>;

export interface TableFiltersSorterValue {
  filters: FiltersValueType;
  sorter: SortInfo[];
  updateFilters: (f: FiltersValueType) => void;
  updateSorter: (f: SortInfo[]) => void;
  reset: () => void;
  hasFiltersAndSorters: boolean;
}

export const TableFiltersSorterContext = React.createContext<TableFiltersSorterValue>({
  filters: {} as FiltersValueType,
  sorter: [] as SortInfo[],
  updateFilters: () => {
    console.error('updateFilters: context does not exist yet!');
  },
  updateSorter: () => {
    console.error('updateSorter: context does not exist yet!');
  },
  reset: () => {
    console.error('reset: context does not exist yet!');
  },
  hasFiltersAndSorters: false,
});

export interface TableFiltersContextProviderProps {
  children: React.ReactNode;
  defaultSorter?: SortInfo[];
}

export const TableFiltersContextProvider: FC<TableFiltersContextProviderProps> = ({
  children,
  defaultSorter = [],
}) => {
  const [map, setMap] = useState({} as FiltersValueType);
  const [sorter, setSorter] = useState(defaultSorter);

  const updateFilters = useCallback((v: FiltersValueType) => {
    setMap(
      {
        ...v
      }
    );
  }, [map, setMap]);

  const updateSorter = useCallback((v: SortInfo[]) => {
    setSorter(
      [...v]
    );
  }, [sorter, setSorter]);

  const reset = useCallback(() => {
    setSorter([]);
    setMap({});
  }, [sorter, setSorter, setMap]);

  const value = useMemo(() => ({
    filters: map,
    sorter,
    updateFilters,
    updateSorter,
    reset,
    hasFiltersAndSorters: !isEmpty(sorter) || !isEmpty(map),
  }), [
    map,
    updateFilters,
    sorter,
    updateSorter,
    reset,
  ]);

  return (
    <TableFiltersSorterContext.Provider value={value}>
      {children}
    </TableFiltersSorterContext.Provider>
  );
};

export const useTableFiltersSorterContext = () => {
  const context = useContext(TableFiltersSorterContext);
  return (context || {}) as TableFiltersSorterValue;
};
