import { useCallback } from 'react';
import { FormOptions, useFormHookFactory } from '../useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../types/form';
import { useGetContextValue } from '../useGetContextValue/useGetContextValue';
import { FrequencyDisplayType } from '../../uc-api-sdk';

export const isDosingFrequencySupported = (frequency?: FrequencyDisplayType) => (
  !(
    [
      undefined,
      null,
      FrequencyDisplayType.QOD,
      FrequencyDisplayType.QWK,
      FrequencyDisplayType.Others,
    ].includes(frequency)
  )
);

export const useMedicationForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    type: {
      name: 'type',
      label: 'Type',
      required: true,
    },
    name: {
      name: 'name',
      label: 'Medication name',
      required: true,
    },
    status: {
      name: 'status',
      label: 'Status',
    },
    otherMD: {
      name: 'otherMD',
      label: "Not prescribed by this patient's primary provider",
    },
    dosage: {
      name: 'dosage',
      label: 'Dosage',
      required: true,
    },
    frequency: {
      name: 'frequency',
      label: 'Dosing Frequency',
      required: true,
    },
    enableReminder: {
      name: 'enableReminder',
      label: '',
    },
    startDate: {
      name: 'startDate',
      label: 'Start date',
    },
    endDate: {
      name: 'endDate',
      label: 'Discontinue date',
    },
    instruction: {
      name: 'instruction',
      label: 'Instruction',
    },
    note: {
      name: 'note',
      label: 'Note',
    }
  }, options);

  const handleDosageFrequencyChange = useCallback((
    value?: FrequencyDisplayType,
  ) => {
    if (!isDosingFrequencySupported(value)) {
      factory.form.setFieldValue(
        factory.getName('enableReminder'),
        false,
      );
    }
  }, []);

  const shouldDisableReminder = useCallback((
    getFieldValue: GetFieldValue,
  ) => (
    !isDosingFrequencySupported(
      factory.getValue('frequency', getFieldValue)
    )
  ), []);

  return useGetContextValue({
    ...factory,
    handleDosageFrequencyChange,
    shouldDisableReminder
  });
};
