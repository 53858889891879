import {
  Col, Form, FormProps, Row, Space
} from 'antd';
import React, { FC, useState } from 'react';
import { MedicationContextProvider } from '../../../../contexts/MedicationContext/MedicationContext';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { useMedicationContainerForm } from '../../../../hooks/useMedicationForm/useMedicationContainerForm';
import { Nullable } from '../../../../uc-api-sdk';
import { CancelSubmitButtonsComponent } from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { FormItem } from '../../../../uiComponent/FormItem/FormItem';
import { MedicationNotesTextAreaComponent } from '../../../../uiComponent/MedicationNotesTextAreaComponent/MedicationNotesTextAreaComponent';
import { MedicationType } from '../../type/type';
import { AddMedicationDrawerComponent } from '../AddMedicationDrawerComponent/AddMedicationDrawerComponent';
import { MedicationComplianceFormItemsComponent } from './MedicationComplianceFormItemsComponent';
import './MedicationTabFormComponent.scss';

export interface MedicationManagementType {
  reconciliationCompliance: string;
  reconciliationComplianceOther?: string;
  otherInfo: string;
  extraNote: string;
}

export interface MedicationTabFormComponentProps {
  form?: FormOptions['form'];
  initialValues?: FormProps['initialValues'];
  onDelete?: (memberId: string) => void;
  onInsert?: (value: MedicationType) => void;
  onUpdate?: (value: MedicationType, id: Nullable<string> | undefined) => void;
  onFinish?: (v: MedicationManagementType) => void;
  showButtons: boolean;
  setTrue: () => void;
  setFalse: () => void;
  children?: React.ReactNode;
  showAdditionalNote?: boolean;
  disabled?: boolean;
  patientId?: string;
}

export const MedicationTabFormComponent:
  FC<MedicationTabFormComponentProps> = ({
    form,
    initialValues,
    onDelete,
    onInsert,
    onUpdate,
    onFinish,
    showButtons,
    setTrue,
    setFalse,
    children,
    showAdditionalNote = true,
    disabled,
    patientId,
  }) => {
    const hook = useMedicationContainerForm({ form });
    const onValuesChange: FormProps['onValuesChange'] = () => {
      setTrue();
    };
    const [prevMeds, setPrevMeds] = useState(0);
    const [curMeds, setCurMeds] = useState(0);
    const showCompliance = (prevMeds + curMeds) > 0;

    const handleSubmit = async (v: MedicationManagementType) => {
      onFinish?.(v);
      setFalse();
    };

    const renderFormItems = () => (
      <>
        <MedicationComplianceFormItemsComponent
          visible={showCompliance}
        />

        {
          (showAdditionalNote && showCompliance)
            ? (
              <Row>
                <Col span={12}>
                  <FormItem
                    className="mt20"
                    info={hook.getInfo('notes')}
                    hidden={!showCompliance}
                  >
                    <MedicationNotesTextAreaComponent />
                  </FormItem>
                </Col>
              </Row>
            ) : null
        }

        {
          showButtons
            ? (
              <FormItem
                className="pb20 mt20"
              >
                <Space>
                  <CancelSubmitButtonsComponent
                    onCancel={setFalse}
                  />
                </Space>
              </FormItem>
            ) : null
        }
      </>
    );

    return (
      <MedicationContextProvider
        prevMeds={prevMeds}
        curMeds={curMeds}
        setPrevMeds={setPrevMeds}
        setCurMeds={setCurMeds}
        onDelete={onDelete}
        onInsert={onInsert}
        onUpdate={onUpdate}
      >
        {
          !hook.form
            ? (
              <Form
                form={hook.form}
                className="mb20"
                id="medicationForm"
                onFinish={hook.handleSubmit(handleSubmit)}
                initialValues={initialValues}
                onValuesChange={onValuesChange}
                layout="vertical"
                disabled={hook.formDisabled}
              >
                {renderFormItems()}
              </Form>
            ) : renderFormItems()
        }
        <Row
          justify="end"
          className="mt30"
        >
          <Col>
            <AddMedicationDrawerComponent
              disabled={hook.formDisabled || disabled}
              patientId={patientId}
            />
          </Col>
        </Row>
        {children}
      </MedicationContextProvider>
    );
  };
