import { DatePicker, Input } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthSubmissionDateFormItemComponent } from '../PriorAuthSubmissionDateFormItemComponent/PriorAuthSubmissionDateFormItemComponent';
import { MissingErrorComponent } from '../../../../uiComponent/MissingErrorComponent/MissingErrorComponent';

export interface PriorAuthApprovalFormItemComponentProps {
  fieldName?: number;
}

export const PriorAuthApprovalFormItemComponent = ({
  fieldName,
}: PriorAuthApprovalFormItemComponentProps) => {
  const insuranceForm = useInsuranceForm();
  return (
    <>
      <PriorAuthSubmissionDateFormItemComponent
        fieldName={fieldName}
      />

      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthApprovalDate', fieldName)}
        label={insuranceForm.getLabel('priorAuthApprovalDate')}
        preserve={false}
        required
      >
        <DatePicker
          format="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
        />
      </FormItem>

      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthAuthorizationNumber', fieldName)}
        label={insuranceForm.getLabel('priorAuthAuthorizationNumber')}
        preserve={false}
        required
      >
        <Input />
      </FormItem>

      <FormItem
        name={insuranceForm.makeListNamePath('priorAuthAuthPeriod', fieldName)}
        label={insuranceForm.getLabel('priorAuthAuthPeriod')}
        preserve={false}
        rules={[
          {
            required: true,
            message: <MissingErrorComponent />,
            validator: (_, value) => (
              new Promise((resolve, reject) => {
                if (!value || !value[0] || !value[1]) {
                  reject();
                }
                resolve(true);
              }))
          }
        ]}
      >
        <DatePicker.RangePicker
          format="MM/DD/YYYY"
          placeholder={['MM/DD/YYYY', 'MM/DD/YYYY']}
        />
      </FormItem>
    </>
  );
};
